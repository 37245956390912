import moment from 'moment';

export const useGetStartDate = () => {
  const getStartDate = () => {
    const now = moment();
    const today_4AM = moment(now).startOf('day').add(4, 'hour');

    if (now.isSameOrAfter(today_4AM)) {
      return now.format('YYYY-MM-DD');
    } else {
      return now.subtract(1, 'days').format('YYYY-MM-DD');
    }
  };

  return {
    getStartDate
  };
};
