import { createApp } from 'vue';
import eventHub from '../../common/modules/EventHub';

import MovieScheduleAreasStatic from './../components/MovieScheduleAreasStatic';
import MovieScheduleAreas from './../components/MovieScheduleAreas';
import { useLocationSelectModal } from '../../composables/useLocationSelectModal.js';
import { useMovieScheduleSwiper } from '../../composables/useMovieSchedueSwiper';
import { useGetStartDate } from '../../composables/useGetStartDate.js';

import PiaAreaAPI from './../../api_clients/PiaArea';
import errorNotification from './../../utils/ErrorNotification';
import {
  DEFAULT_SELECT_AREA_ID,
  LIMIT_NUMBER_WITHOUT_PAGINATING,
  OFFSET_ADDITION
} from '../../constants/MovieSchedule';
import { bugsnagVue } from '../../utils/BugsnagInit';

const piaAreaAPI = new PiaAreaAPI();

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-movie-schedule-static'), (movieScheduleStatic) => {
    const app = createApp({
      setup() {
        return {
          ...useGetStartDate(),
          ...useLocationSelectModal(),
          ...useMovieScheduleSwiper()
        };
      },
      data: function () {
        return {
          movieId: '',
          scheduleDate: '',
          areas: [],
          nextPage: false,
          paginating: true,
          offset: OFFSET_ADDITION, // 最初の5件はrails側のcontrollerから取得
          fetchAreasTimer: null,
          hasAreas: true,
          isAreaRetrieving: false,
          isShowLoading: false,
          selectedPrefId: '',
          selectedPrefName: '',
          selectedAreaId: '',
          selectedAreaName: '',
          selectedDateId: 0,
          isFetchDataByDate: false
        };
      },

      created: function () {
        eventHub.$on('MovieScheduleAreas/on-click-more-read', this.fetchMoreAreas);
      },

      mounted: function () {
        const { dataset } = this.$el.parentElement;

        this.movieId = dataset.movieId;
        this.nextPage = dataset.nextPage;
        this.hasAreas = this.toBoolean(dataset.hasAreas);
        this.selectedPrefId = parseInt(dataset.prefId, 10);
        this.selectedAreaId = parseInt(dataset.areaId, 10);
        this.selectedAreaName = dataset.areaName || '';
        if (dataset.paginating) {
          this.paginating = this.toBoolean(dataset.paginating);
        }
        this.setInitialScheduleDate();
        this.generateSwiper();
      },

      beforeUnmount: function () {
        eventHub.$off('MovieScheduleAreas/on-click-more-read', this.fetchMoreAreas);
      },

      computed: {
        classMoreReadButton: function () {
          return {
            disabled: this.isAreaRetrieving
          };
        },

        classDateItem: function () {
          return {
            disabled: this.isAreaRetrieving
          };
        },

        hasNextPage: function () {
          return this.nextPage === true || this.nextPage === 'true';
        },

        isShowFooter: function () {
          return this.hasNextPage || this.isShowLoading;
        },

        isShowMoreRead: function () {
          return this.hasNextPage && !this.isAreaRetrieving;
        },

        isDateActive: function () {
          return {
            'is-active': this.index === this.activeIndex
          };
        },

        isShowStaticData: function () {
          return this.isFetchDataByDate === false;
        }
      },

      methods: {
        clearAll: function () {
          this.areas = [];
          this.nextPage = false;
          this.offset = 0;
        },

        setInitialScheduleDate: function () {
          this.scheduleDate = this.getStartDate();
        },

        fetchAreas: async function () {
          const params = {
            scheduleDate: this.scheduleDate,
            prefectureId: this.selectedPrefId
          };
          if (this.paginating) {
            params['offset'] = this.offset;
            params['limit'] = OFFSET_ADDITION;
          } else {
            params['limit'] = LIMIT_NUMBER_WITHOUT_PAGINATING;
          }

          if (DEFAULT_SELECT_AREA_ID !== this.selectedAreaId) {
            params.topPriorityAreaId = this.selectedAreaId;
          }

          await piaAreaAPI
            .fetchAreas(this.movieId, params)
            .then((response) => {
              this.clearFetchAreasTimer();
              this.appendAreas(response.data.areas);
              this.updateHasAreas();
              this.updateMetaData(response.data);
            })
            .catch((response) => {
              this.clearFetchAreasTimer();
              errorNotification(response);
            });
        },

        fetchMoreAreas: async function () {
          this.updateIsAreaRetrieving(true);
          this.startFetchAreasTimer(0);
          await this.fetchAreas();
          this.updateIsAreaRetrieving(false);
        },

        fetchNewAreas: async function () {
          this.updateIsAreaRetrieving(true);
          this.clearAll();
          this.updateHasAreas();
          this.startFetchAreasTimer();
          await this.fetchAreas();
          this.updateIsAreaRetrieving(false);
        },

        appendAreas: function (areas) {
          areas.forEach((area) => {
            this.areas.push(area);
          });
        },

        updateHasAreas: function () {
          this.areas.length > 0 ? (this.hasAreas = true) : (this.hasAreas = false);
        },

        updateIsAreaRetrieving: function (bool) {
          this.isAreaRetrieving = bool;
        },

        startFetchAreasTimer: function (ms = 600) {
          this.fetchAreasTimer = setTimeout(() => {
            this.updateShowLoading(true);
          }, ms);
        },

        clearFetchAreasTimer: function () {
          this.updateShowLoading(false);
          clearTimeout(this.fetchAreasTimer);
          this.fetchAreasTimer = null;
        },

        updateShowLoading: function (bool) {
          this.isShowLoading = bool;
        },

        updateMetaData: function (data) {
          this.nextPage = data.nextPage;
          this.updateOffset();
        },

        updateOffset: function () {
          if (this.nextPage === true) {
            this.offset += OFFSET_ADDITION;
          }
        },

        onClickMoreRead: function () {
          this.fetchMoreAreas();
        },

        onClickScheduleDate: function (id, date) {
          this.selectedDateId = id;
          this.isFetchDataByDate = true;
          this.scheduleDate = date;
          this.fetchNewAreas();
        },

        toBoolean: function (val) {
          return val === 'true';
        }
      },

      components: {
        MovieScheduleAreasStatic,
        MovieScheduleAreas
      }
    });

    app.use(bugsnagVue).mount(movieScheduleStatic);
  });
});
