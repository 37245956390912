import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  new Swiper('#js-recommend-swiper', {
    wrapperClass: 'p-content-detail__recommend-carousel__wrapper',
    slideClass: 'c-content-item',
    navigation: {
      nextEl: '.p-content-detail__recommend-carousel__next',
      prevEl: '.p-content-detail__recommend-carousel__prev'
    },
    slidesPerView: 'auto',
    loop: false,
    allowTouchMove: false,
    spaceBetween: 10,
    slidesPerGroup: 6
  });
});
