import { createApp } from 'vue';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useAccordionRadio } from '../../composables/useAccordionRadio';
import { useReadMore } from '../../composables/useReadMore';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-content-detail-related-info')) {
    const app = createApp({
      data() {
        return {
          isShowNavi: true
        };
      },

      mounted: function () {
        this.$nextTick(() => {
          const naviList = document.querySelectorAll('.js-tab-navi');
          if (naviList.length > 0) {
            naviList[0].classList.add('is-active');
            const contentList = document.querySelectorAll('.js-tab-content');
            contentList[0].classList.add('is-active');
          } else {
            this.isShowNavi = false;
          }
        });
      },

      methods: {
        showTab: function (name) {
          Array.prototype.forEach.call(document.querySelectorAll('.js-tab-navi'), (tab) => {
            tab.classList.remove('is-active');
          });
          const navi = document.getElementById('js-tab-navi__' + name);
          navi.classList.add('is-active');
          Array.prototype.forEach.call(document.querySelectorAll('.js-tab-content'), (tab) => {
            tab.classList.remove('is-active');
          });
          const content = document.getElementById('js-tab-content__' + name);
          content.classList.add('is-active');
        }
      },

      setup() {
        return {
          ...useReadMore(),
          ...useAccordionRadio()
        };
      }
    });

    app.use(bugsnagVue).mount('#js-content-detail-related-info');
  }
});
