import { createApp } from 'vue';
import OtherLinksModal from './../components/OtherLinksModal';
import ModalOverlay from './../components/ModalOverlay';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-other-links-modal')) {
    const app = createApp({
      components: {
        OtherLinksModal,
        ModalOverlay
      },

      data() {
        return {
          show: false
        };
      },

      methods: {
        onClickVisible: function () {
          this.show = !this.show;
        },
        onCloseModal: function () {
          this.show = false;
        }
      }
    });

    app.use(bugsnagVue).mount('#js-other-links-modal');
  }
});
