import eventHub from '../common/modules/EventHub';
import PrivacyPolicyAcceptAPI from '../api_clients/PrivacyPolicyAccept';
import errorNotification from '../utils/ErrorNotification';
import { ref } from 'vue';

const privacyPolicyAcceptAPI = new PrivacyPolicyAcceptAPI();

export const usePrivacyPolicy = () => {
  const isAccepted = ref(false);
  const updateAcceptsPath = ref('');
  const isApiProcessing = ref(false);
  const modalType = ref('privacy-policy');

  const toBoolean = (val) => {
    return val === 'true';
  };

  const onHookMounted = () => {
    eventHub.$on('update-privacy-policy-accept', updatePrivacyPolicyAccept);

    const element = document.querySelector('#js-privacy-policy-accept');
    updateAcceptsPath.value = element.dataset.updateAcceptsPath;
  };

  const onHookBeforeUnmount = () => {
    eventHub.$off('update-privacy-policy-accept', updatePrivacyPolicyAccept);
  };

  const updatePrivacyPolicyAccept = () => {
    isApiProcessing.value = true;
    privacyPolicyAcceptAPI
      .updatePrivacyPolicyAccept()
      .then((response) => {
        if (response.data.user.isPrivacyPolicyAccepted === true) {
          isAccepted.value = true;
        }
        isApiProcessing.value = false;
      })
      .catch((response) => {
        errorNotification(response);
        isApiProcessing.value = false;
      });
  };

  return {
    isAccepted,
    updateAcceptsPath,
    isApiProcessing,
    modalType,
    toBoolean,
    updatePrivacyPolicyAccept,
    onHookMounted,
    onHookBeforeUnmount
  };
};
