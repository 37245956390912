import { ref, onMounted } from 'vue';
import PiaTheaterAPI from './../api_clients/PiaTheater';
import errorNotification from './../utils/ErrorNotification';

export function useTheaterCard(theaterId) {
  const movies = ref([]);
  const totalMovie = ref('');
  const isShow = ref(false);

  async function fetchMovies() {
    try {
      const response = await new PiaTheaterAPI().fetchRecentMovies(theaterId);
      movies.value = response.data.movies;

      if (movies.value.length > 0) {
        totalMovie.value = movies.value.length + '作品';
      }

      isShow.value = true;
    } catch (error) {
      errorNotification(error);
    }
  }

  onMounted(() => {
    fetchMovies();
  });

  return { movies, totalMovie, isShow };
}
