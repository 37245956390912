import * as message from './../constants/Message';

export const visibleSpoiler = (elm) => {
  if (window.confirm(message.SHOW_SPOILER_CONFIRM)) {
    elm.parentNode.removeChild(elm);
  }
};

export const visibleSpoilerWithTag = (elm) => {
  if (window.confirm(message.SHOW_SPOILER_CONFIRM)) {
    elm.parentNode.removeChild(elm);
    return true;
  }
  return false;
};

export const onClickVisibleSpoiler = (elm) => {
  elm.addEventListener(
    'click',
    (e) => {
      if (e) {
        e.preventDefault();
      }

      visibleSpoiler(elm);
    },
    false
  );
};
