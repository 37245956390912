import ApiBase from './ApiBase';

export default class PiaArea extends ApiBase {
  constructor() {
    super();
  }

  fetchAreas(movieId, params) {
    return this.client.get(`/v2/movies/${movieId}/areas`, { params: params });
  }

  fetchRegions(movieId) {
    return this.client.get(`/v2/movies/${movieId}/regions`);
  }

  fetchPrefAreas(movieId, prefectureId) {
    return this.client.get(`/v2/movies/${movieId}/prefectures/${prefectureId}/areas`);
  }
}
