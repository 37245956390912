import Base from './Base';
import { transformRequest } from '../utils/TextFormat';

export default class Movie extends Base {
  constructor() {
    super();
  }

  // Mark Mark投稿
  saveMark(movieId, params) {
    return this.client.post(`/movies/${movieId}/marks`, params, { transformRequest: [transformRequest] });
  }

  // Mark Mark詳細取得
  fetchMark(movieId, markId, params) {
    return this.client.get(`/movies/${movieId}/marks/${markId}`, { params: params });
  }

  // Mark Mark更新
  updateMark(movieId, markId, params) {
    return this.client.patch(`/movies/${movieId}/marks/${markId}`, params, { transformRequest: [transformRequest] });
  }

  // Mark Mark削除
  deleteMark(movieId, markId, params) {
    return this.client.delete(`/movies/${movieId}/marks/${markId}`, params);
  }

  // Clip 映画をClipする
  saveClip(movieId, params) {
    return this.client.post(`/movies/${movieId}/clips`, params);
  }

  // Clip 映画のClipを解除する
  deleteClip(movieId, clipId, params) {
    return this.client.delete(`/movies/${movieId}/clips/${clipId}`, params);
  }

  // MarkLike Markにいいね！ 登録
  saveMarkLike(movieId, markId, params) {
    return this.client.post(`/movies/${movieId}/marks/${markId}/likes`, params);
  }

  // MarkLike Markにつけたいいね！ 解除
  deleteMarkLike(movieId, markId, markLikeId, params) {
    return this.client.delete(`/movies/${movieId}/marks/${markId}/likes/${markLikeId}`, params);
  }

  // MarkComment コメントを投稿
  saveMarkComment(movieId, markId, params) {
    return this.client.post(`/movies/${movieId}/marks/${markId}/comments`, params);
  }

  // MarkComment コメントを取得
  fetchMarkComment(movieId, markId, params) {
    return this.client.get(`/movies/${movieId}/marks/${markId}/comments`, { params: params });
  }

  // MarkComment コメントを削除
  deleteMarkComment(movieId, markId, markCommentId, params) {
    return this.client.delete(`/movies/${movieId}/marks/${markId}/comments/${markCommentId}`, params);
  }

  // ユーザ Markにいいね！したユーザー一覧
  fetchMarkLikeUsers(movieId, markId, params) {
    return this.client.get(`/movies/${movieId}/marks/${markId}/likes/users`, { params: params });
  }
}
