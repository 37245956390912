import { createApp, onMounted } from 'vue';
import { useEpisodeReadMore } from '../../composables/useEpisodeReadMore';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useAccordionRadio } from '../../composables/useAccordionRadio';

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.js-episode-read-more')).forEach((el) => {
    const app = createApp({
      setup() {
        const { opened_id, isAccordionOpen, clickItem } = useAccordionRadio();
        const { isClick, readMore, isReadMore, isReadMoreButton, onEpisodeReadMoreMounted } = useEpisodeReadMore();

        onMounted(() => {
          onEpisodeReadMoreMounted(el.dataset.readMoreThreshold);
        });

        return {
          opened_id,
          isAccordionOpen,
          clickItem,
          isClick,
          readMore,
          isReadMore,
          isReadMoreButton
        };
      }
    });

    app.use(bugsnagVue).mount(el);
  });
});
