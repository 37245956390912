import { createApp } from 'vue';
import { useLocationSelectModal } from '../../composables/useLocationSelectModal';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-theater-static'), (theaterStatic) => {
    const app = createApp({
      setup() {
        return {
          ...useLocationSelectModal()
        };
      },

      data: function () {
        return {
          selectedPrefId: '',
          selectedAreaId: ''
        };
      },

      mounted: function () {
        this.selectedPrefId = parseInt(this.$el.parentElement.dataset.prefId, 10);
        this.selectedAreaId = parseInt(this.$el.parentElement.dataset.areaId, 10);
      }
    });

    app.use(bugsnagVue).mount(theaterStatic);
  });
});
