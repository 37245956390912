import { createApp } from 'vue';
import { useMovieScheduleSwiper } from '../../composables/useMovieSchedueSwiper';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-movie-schedule-swiper'), (movieScheduleSwiper) => {
    const app = createApp({
      setup() {
        return {
          ...useMovieScheduleSwiper()
        };
      }
    });

    app.use(bugsnagVue).mount(movieScheduleSwiper);
  });
});
