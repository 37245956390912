import { createApp } from 'vue';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-content-list__item'), (listItem) => {
    const app = createApp({
      methods: {
        onClickLink: function (link) {
          window.location.href = link;
        }
      }
    });

    app.use(bugsnagVue).mount(listItem);
  });
});
