import Base from './Base';

export default class PrivacyPolicyAccept extends Base {
  constructor() {
    super();
  }

  updatePrivacyPolicyAccept(params) {
    return this.client.patch('/privacy_policy_accept');
  }
}
