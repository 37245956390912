export default class UserAgent {
  constructor() {
    this.userAgent = this._getUserAgent();
    this.platform = this._getPlatform();
  }

  _getUserAgent() {
    return window.navigator.userAgent.toLowerCase();
  }

  _getPlatform() {
    let platform = '';
    if (window.navigator.platform) {
      platform = window.navigator.platform;
    }
    return platform;
  }

  // 対応バージョン：Vista, 7, 8, 8.1, 10のみ
  _getWinVersions() {
    let winOsVersion = '';
    if (this.userAgent.match(/win(dows\s)?nt\s10\.0/)) {
      winOsVersion = 'Windows 10';
    } else if (this.userAgent.match(/win(dows\s)?nt\s6\.3/)) {
      winOsVersion = 'Windows 8.1';
    } else if (this.userAgent.match(/win(dows\s)?nt\s6\.2/)) {
      winOsVersion = 'Windows 8';
    } else if (this.userAgent.match(/win(dows\s)?nt\s6\.1/)) {
      winOsVersion = 'Windows 7';
    } else if (this.userAgent.match(/win(dows\s)?nt\s6\.0/)) {
      winOsVersion = 'Windows Vista';
    } else {
      const matches = this.userAgent.match(/win(dows\s)?ns\s(\d.*?);/);
      if (matches) {
        winOsVersion = `Windows NT ${matches[2]}`;
      }
    }
    return winOsVersion;
  }

  _getMacOs() {
    let macOsName = '';
    const matches = this.userAgent.match(/mac os x/);
    if (matches) {
      macOsName = 'Mac OS X';
    } else {
      macOsName = 'MacOS';
    }
    return macOsName;
  }

  _getMacVersion() {
    let macOsVersion = '';
    const matches = this.userAgent.match(/mac os x ([.\d_]+)/);
    if (matches) {
      macOsVersion = matches[1];
    } else {
      macOsVersion = 'macOS';
    }
    return macOsVersion;
  }

  getBrowserName() {
    let browserName = '';
    if (this.userAgent.indexOf('msie') !== -1 || this.userAgent.indexOf('trident') !== -1) {
      // IE
      browserName = 'MSIE';
    } else if (this.userAgent.indexOf('edge') !== -1) {
      // Edge
      browserName = 'Edge';
    } else if (this.userAgent.indexOf('chrome') !== -1) {
      // Chrome
      browserName = 'Chrome';
    } else if (this.userAgent.indexOf('safari') !== -1) {
      // Safari
      browserName = 'Safari';
    } else if (this.userAgent.indexOf('firefox') !== -1) {
      // Firefox
      browserName = 'Firefox';
    } else if (this.userAgent.indexOf('opera') !== -1) {
      // Opera
      browserName = 'Opera';
    }
    return browserName;
  }

  // Chrome、Firefox, IE、Safariのみ対応
  getBrowserVersion() {
    let browserVersion = '';
    const browserName = this.getBrowserName();

    if (browserName === 'Chrome') {
      const matches = this.userAgent.match(/chrome\/([.\d]+)/);
      if (matches) {
        browserVersion = matches[1];
      }
    } else if (browserName === 'Firefox') {
      const matches = this.userAgent.match(/firefox\/([.\d]+)/);
      if (matches) {
        browserVersion = matches[1];
      }
    } else if (browserName === 'MSIE') {
      const isIE11 = this.userAgent.match(/trident\/7/);
      if (isIE11) {
        browserVersion = 'IE 11';
      } else {
        const matches = this.userAgent.match(/msie ([.\d]+)/);
        if (matches) {
          browserVersion = `IE ${matches[1]}`;
        }
      }
    } else if (browserName === 'Safari') {
      const matches = this.userAgent.match(/version\/([.\d]+)/);
      if (matches) {
        browserVersion = matches[1];
      }
    }
    return browserVersion;
  }

  getOs() {
    let os = '';
    const platform = this.platform.toLowerCase();

    if (platform === 'macintel') {
      // Mac
      os = this._getMacOs();
    } else if (platform.indexOf('win') !== -1) {
      // Windows
      os = this.platform;
    }
    return os;
  }

  // Mac, Windows(Vista~10まで)のみ対応
  getOsVersion() {
    let osVersion = '';
    const platform = this.platform.toLowerCase();

    if (platform === 'macintel') {
      // Mac
      osVersion = this._getMacVersion();
    } else if (platform.indexOf('win') !== -1) {
      // Windows
      osVersion = this._getWinVersions();
    }
    return osVersion;
  }

  getDevice() {
    if (
      this.userAgent.indexOf('iphone') > 0 ||
      (this.userAgent.indexOf('android') > 0 && this.userAgent.indexOf('mobile') > 0)
    ) {
      // iPhone, Android
      return 'Phone';
    } else if (this.userAgent.indexOf('ipad') > 0 || this.userAgent.indexOf('android') > 0) {
      // iPad, AndroidTablet
      return 'Tablet';
    } else {
      return 'PC';
    }
  }

  update() {
    this.userAgent = this._getUserAgent();
  }

  isIOS() {
    if (
      this.userAgent.indexOf('iphone') !== -1 ||
      this.userAgent.indexOf('ipad') !== -1 ||
      this.userAgent.indexOf('ipod') !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  isAndroidOS() {
    if (this.userAgent.indexOf('android') !== -1) {
      return true;
    } else {
      return false;
    }
  }
}
