import { createApp } from 'vue';
import MarkLike from '../components/MarkLike';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-mark-card'), (el) => {
    const app = createApp({
      methods: {
        toBoolean: function (val) {
          return val === 'true';
        },

        toNumber: function (val) {
          return parseInt(val, 10);
        }
      },

      components: {
        MarkLike
      }
    });

    app.use(bugsnagVue).mount(el);
  });
});
