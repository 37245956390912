import eventHub from '../common/modules/EventHub';
import { timeFormat } from '../utils/TextFormat';
import { computed } from 'vue';

export const productMarkProps = {
  markComment: {
    type: Object,
    required: true
  },
  calledPage: {
    type: String,
    default: ''
  }
};

export const useProductMarkComment = (props) => {
  const isCalledFeed = computed(() => {
    return props.calledPage === 'feed';
  });

  const onClickSpoiler = (markCommentId) => {
    eventHub.$emit('open-spoiler', { markCommentId });
  };

  const formattedTime = (sepType) => {
    return timeFormat(props.markComment.publishedAt || props.markComment.createdAt, sepType);
  };

  return {
    isCalledFeed,
    onClickSpoiler,
    formattedTime
  };
};
