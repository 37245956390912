import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import _findIndex from 'lodash/findIndex';
import AnimeAPI from './../../api_clients/Anime';
import errorNotification from './../../utils/ErrorNotification';
import AnimeMarkCommentItem from './../components/AnimeMarkCommentItem';
// #8266 コメント登録・削除機能実装後反映
// import MarkPostComment from './../components/MarkPostComment';
import * as message from './../../constants/Message';
import { bugsnagVue } from '../../utils/BugsnagInit';

const animeAPI = new AnimeAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-anime-review-detail-comment')) {
    const jsAnimeReviewDetailComment = document.getElementById('js-anime-review-detail-comment');
    const app = createApp({
      el: '#js-anime-review-detail-comment',

      data() {
        return {
          animeSeriesId: null,
          animeSeasonId: null,
          markId: null,
          markComments: [],
          nextPage: true,
          page: 1,
          count: 0
        };
      },

      created: function () {
        eventHub.$on('open-spoiler', this.openSpoiler);
      },

      mounted: function () {
        this.$nextTick(() => {
          const { dataset } = this.$el.parentElement;

          this.animeSeriesId = dataset.animeSeriesId;
          this.animeSeasonId = dataset.animeSeasonId;
          this.markId = dataset.markId;
          this.count = dataset.count;

          this.fetchComment();
        });
      },

      beforeUnmount: function () {
        eventHub.$off('open-spoiler', this.openSpoiler);
      },

      methods: {
        onClickMoreRead: function () {
          this.fetchComment();
        },

        onClickSpoiler: function (markCommentId, e) {
          if (window.confirm(message.SHOW_SPOILER_CONFIRM)) {
            const spoilerIndex = _findIndex(this.markComments, { id: markCommentId });

            if (spoilerIndex >= 0) {
              this.markComments[spoilerIndex].isSpoiler = false;
            }
          }
        },

        fetchComment: function () {
          const params = {
            page: this.page
          };

          animeAPI
            .fetchMarkComment(this.animeSeriesId, this.animeSeasonId, this.markId, params)
            .then((response) => {
              this.setMarkCommentsData(response.data);
              this.page = this.page + 1;
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        setMarkCommentsData: function (data) {
          this.nextPage = data.nextPage;
          this.markComments = this.markComments.concat(data.markComments).sort(function (a, b) {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          });
        },

        toBoolean: function (val) {
          return val === 'true';
        },

        openSpoiler: function (hubEvent) {
          this.onClickSpoiler(hubEvent?.data?.markCommentId);
        }
      },

      components: {
        AnimeMarkCommentItem
      }
    });

    app.use(bugsnagVue).mount(jsAnimeReviewDetailComment);
  }
});
