import axios from 'axios';

const apiBase = '';

export default class Base {
  constructor() {
    this.client = this._createClient(apiBase);
  }

  _createClient(apiBase) {
    const token = document.getElementsByName('csrf-token')[0].content;

    if (token) {
      return axios.create({
        baseURL: apiBase,
        timeout: 6000,
        headers: { 'X-CSRF-Token': token }
      });
    } else {
      return axios.create({
        baseURL: apiBase,
        timeout: 6000
      });
    }
  }
}
