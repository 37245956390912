import { ref } from 'vue';

export const useAccordionToggle = () => {
  const opened_ids = ref([]);

  const isAccordionOpen = (id) => {
    return opened_ids.value.includes(id);
  };

  const clickItem = (id) => {
    if (isAccordionOpen(id)) {
      opened_ids.value = opened_ids.value.filter((v) => v != id);
    } else {
      opened_ids.value = [...opened_ids.value, id];
    }
  };

  return {
    opened_ids,
    isAccordionOpen,
    clickItem
  };
};
