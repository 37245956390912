import { createApp } from 'vue';
import OnlinePreviewAllowedEnv from './../modules/OnlinePreviewAllowedEnv';
import OnlinePreviewItem from '../components/OnlinePreviewItem';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-online-preview')) {
    const app = createApp({
      mixins: [OnlinePreviewAllowedEnv],

      components: {
        OnlinePreviewItem
      }
    });

    app.use(bugsnagVue).mount('#js-online-preview');
  }
});
