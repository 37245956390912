import { createApp } from 'vue';
import AreaAPI from '../../api_clients/Area';
import errorNotification from '../../utils/ErrorNotification';
import NearestAreas from '../components/NearestAreas';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.js-nearest-area')).forEach((el) => {
    const app = createApp({
      el,
      data() {
        return {
          areas: [],
          areaId: '',
          prefectureId: '',
          isLoading: true,
          isReadMore: false
        };
      },
      methods: {
        fetchNearestAreas: function (offset, limit) {
          const areaAPI = new AreaAPI();

          const params = {
            areaId: this.areaId,
            prefectureId: this.prefectureId,
            limit: limit,
            offset: offset
          };

          return areaAPI.fetchNearestAreas(params);
        },

        readMore: function () {
          const offset = this.areas.length;
          const limit = 5;
          this.isLoading = true;
          this.isReadMore = false;
          this.fetchNearestAreas(offset, limit)
            .then((response) => {
              if (response.request.status !== 200) {
                throw new Error(response);
              }

              this.areas = [...this.areas, ...response.data.piaAreas];
              this.isLoading = false;

              if (response.data.piaAreas.length == limit) {
                this.isReadMore = true;
              }
            })
            .catch((error) => {
              errorNotification(error);
            });
        }
      },
      mounted: function () {
        const offset = 0;
        const limit = 4;
        this.isLoading = true;
        this.isReadMore = false;
        this.areaId = this.$el.parentElement.dataset.areaId;
        this.prefectureId = this.$el.parentElement.dataset.prefectureId;
        this.fetchNearestAreas(offset, limit)
          .then((response) => {
            if (response.request.status !== 200) {
              throw new Error(response);
            }

            this.areas = [...this.areas, ...response.data.piaAreas];
            this.isLoading = false;

            if (response.data.piaAreas.length == limit) {
              this.isReadMore = true;
            }
          })
          .catch((error) => {
            errorNotification(error);
          });
      },
      components: {
        NearestAreas
      }
    });

    app.use(bugsnagVue).mount(el);
  });
});
