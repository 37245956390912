import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import _findIndex from 'lodash/findIndex';
import DramaAPI from './../../api_clients/Drama';
import errorNotification from './../../utils/ErrorNotification';
import DramaMarkCommentItem from './../components/DramaMarkCommentItem';
// #8266 コメント登録・削除機能実装後反映
// import MarkPostComment from './../components/MarkPostComment';
import * as message from './../../constants/Message';
import { bugsnagVue } from '../../utils/BugsnagInit';

const dramaAPI = new DramaAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-drama-review-detail-comment')) {
    const app = createApp({
      data() {
        return {
          dramaSeriesId: null,
          dramaSeasonId: null,
          markId: null,
          markComments: [],
          nextPage: true,
          page: 1,
          count: 0
        };
      },

      created: function () {
        eventHub.$on('open-spoiler', this.openSpoiler);
      },

      mounted: function () {
        this.$nextTick(() => {
          const { dataset } = this.$el.parentElement;

          this.dramaSeriesId = dataset.dramaSeriesId;
          this.dramaSeasonId = dataset.dramaSeasonId;
          this.markId = dataset.markId;
          this.count = dataset.count;

          this.fetchComment();
        });
      },

      beforeUnmount: function () {
        eventHub.$off('open-spoiler', this.openSpoiler);
      },

      methods: {
        onClickMoreRead: function () {
          this.fetchComment();
        },

        // onClickDelete: function(markCommentId, e) {
        //   if (window.confirm(message.DELETE_COMMENT_CONFIRM)) {
        //     this.deleteComment(parseInt(markCommentId, 10), e);
        //   }
        // },

        onClickSpoiler: function (markCommentId, e) {
          if (window.confirm(message.SHOW_SPOILER_CONFIRM)) {
            const spoilerIndex = _findIndex(this.markComments, { id: markCommentId });

            if (spoilerIndex >= 0) {
              this.markComments[spoilerIndex].isSpoiler = false;
            }
          }
        },

        fetchComment: function () {
          const params = {
            page: this.page
          };

          dramaAPI
            .fetchMarkComment(this.dramaSeriesId, this.dramaSeasonId, this.markId, params)
            .then((response) => {
              this.setMarkCommentsData(response.data);
              this.page = this.page + 1;
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        // #8266 コメント登録・削除機能実装後反映
        // saveComment: function(bodyText, isSpoiler) {
        //   const params = {
        //     bodyText: bodyText,
        //     isSpoiler: isSpoiler
        //   };

        //   dramaAPI.saveMarkComment(this.dramaSeriesId, this.dramaSeasonId, this.markId, params).then(response => {
        //     this.setCount(response.data);
        //     this.setMyMarkCommentData(response.data);
        //     this.$broadcast('comment-saved');
        //   }).catch(response => {
        //     this.$refs.errorModal.showIfDetail(response);
        //   });
        // },

        // deleteComment: function(markCommentId, e) {
        //   dramaAPI.deleteMarkComment(this.movieId, this.markId, markCommentId).then(response => {
        //     this.setCount(response.data);
        //     this.removeMarkCommentData(markCommentId, e);
        //   }).catch(response => {
        //     errorNotification(response);
        //   });
        // },

        // setCount: function(data) {
        //   this.count = data.markComment.count;
        // },

        // setMyMarkCommentData: function(data) {
        //   this.markComments.push(data.markComment);
        // },

        setMarkCommentsData: function (data) {
          this.nextPage = data.nextPage;
          this.markComments = this.markComments.concat(data.markComments).sort(function (a, b) {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          });
        },

        // #8266 コメント登録・削除機能実装後反映
        // removeMarkCommentData: function(markCommentId, e) {
        //   const deleteIndex = findIndex(this.markComments, { 'id': markCommentId });
        //   this.markComments.splice(deleteIndex, 1);
        // },

        toBoolean: function (val) {
          return val === 'true';
        },

        openSpoiler: function (hubEvent) {
          this.onClickSpoiler(hubEvent?.data?.markCommentId);
        }
      },

      // #8266 コメント登録・削除機能実装後反映 eventsは削除してeventHubを使うようにする
      // events: {
      //   // 'save-mark-comment': function(bodyText, isSpoiler) {
      //   //   if (!bodyText) {
      //   //     window.alert(message.PLEASE_INPUT_COMMENT);
      //   //     return;
      //   //   }
      //
      //   //   this.saveComment(bodyText, isSpoiler);
      //   // },
      //
      //   // 'delete-comment': function(markCommentId) {
      //   //   this.onClickDelete(markCommentId);
      //   // }
      // },

      components: {
        DramaMarkCommentItem
      }
    });

    app.use(bugsnagVue).mount('#js-drama-review-detail-comment');
  }
});
