import { createApp } from 'vue';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useReadMore } from '../../composables/useReadMore';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-award-list')) {
    const app = createApp({
      setup() {
        return {
          ...useReadMore()
        };
      }
    });

    app.use(bugsnagVue).mount('#js-award-list');
  }
});
