import Treasure from 'td-js-sdk';
import moment from 'moment';
import UserAgent from './../../utils/UserAgent';

export default {
  data: function () {
    return {
      treasureDataOptions: {
        protocol: 'https',
        host: 'in.treasuredata.com',
        pathname: '/js/v3/event/',
        writeKey: null,
        database: null,
        tableName: 'movie_play_log'
      },
      treasureDataClient: null,
      treasureDataSendData: {
        userPlatformId: '',
        siteCode: '116',
        os: '',
        osVersion: '',
        appVersion: '1.0.0',
        userAgent: '',
        browserType: '',
        browserVersion: '',
        screenType: '',
        servicePlatform: 'filmarks',
        deviceName: 'PC',
        sakuhinCode: '',
        sakuhinName: '',
        episodeCode: '',
        episodeName: '',
        movieFileCode: '',
        playbackTime: '',
        playbackStartTime: '',
        playbackEndTime: '',
        playTime: '',
        commodityCode: '',
        saleTypeCode: '',
        playbackStatus: 0,
        superUserFlg: null,
        tokenHash: ''
      },
      treasureDataPlaybackFormat: 'YYYY-MM-DD HH:mm:ss'
    };
  },

  methods: {
    createTreasureDataClient: function () {
      this.treasureDataClient = new Treasure({
        protocol: this.treasureDataOptions.protocol,
        host: this.treasureDataOptions.host,
        pathname: this.treasureDataOptions.pathname,
        writeKey: this.treasureDataOptions.writeKey,
        database: this.treasureDataOptions.database
      });
      this.treasureDataClient.set('$global', 'td_global_id', 'td_global_id');
    },

    setTreasureDataSendData: function (unextData) {
      const ua = new UserAgent();

      this.treasureDataOptions.database = unextData.treasureDataDbName;
      this.treasureDataOptions.writeKey = unextData.treasureDataKey;

      this.treasureDataSendData.userPlatformId = unextData.userPlatformId;
      this.treasureDataSendData.tokenHash = unextData.licensePlayTokenHash;
      this.treasureDataSendData.sakuhinName = unextData.titleDisplayName;
      this.treasureDataSendData.sakuhinCode = unextData.titleCode;
      this.treasureDataSendData.episodeName = unextData.episodeName;
      this.treasureDataSendData.episodeCode = unextData.episodeCode;
      this.treasureDataSendData.movieFileCode = unextData.episodeMovieSourceFileCode;
      this.treasureDataSendData.commodityCode = unextData.playlistProductCode;
      this.treasureDataSendData.saleTypeCode = unextData.playlistSaleTypeCode;
      this.treasureDataSendData.superUserFlg = unextData.treasureDataSuperUserFlg;

      this.treasureDataSendData.userAgent = ua.userAgent;
      this.treasureDataSendData.browserType = ua.getBrowserName();
      this.treasureDataSendData.browserVersion = ua.getBrowserVersion();
      this.treasureDataSendData.os = ua.getOs();
      this.treasureDataSendData.osVersion = ua.getOsVersion();
      this.treasureDataSendData.screenType = ua.getDevice();
    },

    setTreasureDataPlayTime(sec) {
      this.treasureDataSendData.playTime = sec;
    },

    setTreasureDataPlayBackStartEndTime(target) {
      const now = moment().format(this.treasureDataPlaybackFormat);
      if (target === 'start') {
        this.treasureDataSendData.playbackStartTime = now;
      } else if (target === 'end') {
        this.treasureDataSendData.playbackEndTime = now;
      }
    },

    setTreasureDataPlayBackTime() {
      if (this.treasureDataSendData.playbackStartTime !== '' && this.treasureDataSendData.playbackEndTime !== '') {
        const start = moment(this.treasureDataSendData.playbackStartTime);
        const end = moment(this.treasureDataSendData.playbackEndTime);
        this.treasureDataSendData.playbackTime = end.diff(start, 'seconds'); // secでセットする
      }
    },

    sendTreasureData() {
      if (this.treasureDataClient) {
        this.createTreasureDataClient();
      }

      this.treasureDataClient.trackEvent(this.treasureDataOptions.tableName, {
        user_platform_id: this.treasureDataSendData.userPlatformId,
        site_code: this.treasureDataSendData.siteCode,
        os: this.treasureDataSendData.os,
        os_version: this.treasureDataSendData.osVersion,
        app_version: this.treasureDataSendData.appVersion,
        user_agent: this.treasureDataSendData.userAgent,
        browser_type: this.treasureDataSendData.browserType,
        browser_version: this.treasureDataSendData.browserVersion,
        screen_type: this.treasureDataSendData.screenType,
        service_platform: this.treasureDataSendData.servicePlatform,
        device_name: this.treasureDataSendData.deviceName,
        sakuhin_code: this.treasureDataSendData.sakuhinCode,
        sakuhin_name: this.treasureDataSendData.sakuhinName,
        episode_code: this.treasureDataSendData.episodeCode,
        episode_name: this.treasureDataSendData.episodeName,
        movie_file_code: this.treasureDataSendData.movieFileCode,
        playback_time: this.treasureDataSendData.playbackTime,
        playback_start_time: this.treasureDataSendData.playbackStartTime,
        playback_end_time: this.treasureDataSendData.playbackEndTime,
        play_time: this.treasureDataSendData.playTime,
        commodity_code: this.treasureDataSendData.commodityCode,
        sale_type_code: this.treasureDataSendData.saleTypeCode,
        playback_status: this.treasureDataSendData.playbackStatus,
        super_user_flg: this.treasureDataSendData.superUserFlg,
        token_hash: this.treasureDataSendData.tokenHash
      });
    },

    deleteTreasureDataClient: function () {
      this.treasureDataClient = null;
    }
  }
};
