import { createApp } from 'vue';
import { useTwitterRelation } from '../../composables/useTwitterRelation';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-twitter-relation')) {
    const app = createApp({
      setup() {
        const {
          isRelated,
          isRelatedClip,
          onHookMounted,
          onClickRelation,
          onClickClip,
          createToken,
          deleteToken,
          updateRelationClip
        } = useTwitterRelation();

        return {
          isRelated,
          isRelatedClip,
          onClickRelation,
          onHookMounted,
          onClickClip,
          createToken,
          deleteToken,
          updateRelationClip
        };
      },
      mounted() {
        this.onHookMounted(this.$refs.twitterRelationRef.dataset);
      }
    });

    app.use(bugsnagVue).mount('#js-twitter-relation');
  }
});
