import { computed } from 'vue';

export const contentDetailSynopsisProps = {
  outline: {
    type: String,
    default: ''
  },
  truncatedOutline: {
    type: String,
    default: ''
  },
  isOverLimitLength: {
    type: [String, Boolean]
  }
};

export const useContentDetailSynopsis = (props) => {
  const isOverLimitLengthToBoolean = computed(() => props.isOverLimitLength === 'true');

  return {
    isOverLimitLengthToBoolean
  };
};
