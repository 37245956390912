export const useAnchorLink = () => {
  const onClickAnchor = (id) => {
    const targetElement = document.getElementById(id);
    const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth'
    });
  };

  return {
    onClickAnchor
  };
};
