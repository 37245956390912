import { ref } from 'vue';
import SnsAPI from '../api_clients/SNS';
import errorNotification from '../utils/ErrorNotification';
import * as message from '../constants/Message';

const snsAPI = new SnsAPI();

export const useFacebookRelation = () => {
  const isRelated = ref(false);

  const onClickRelation = () => {
    if (!isRelated.value) {
      createToken();
    } else {
      if (window.confirm(message.DELETE_FACEBOOK_RELATION_CONFIRM)) {
        deleteToken();
      }
    }
  };

  const createToken = () => {
    snsAPI.createFacebookToken();
  };

  const deleteToken = () => {
    snsAPI
      .deleteFacebookToken()
      .then((response) => {
        isRelated.value = false;
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  const onHookMounted = () => {
    isRelated.value = document.querySelector('#js-facebook-relation').dataset.facebook_related === 'true';
  };

  return {
    isRelated,
    onClickRelation,
    createToken,
    deleteToken,
    onHookMounted
  };
};
