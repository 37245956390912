import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  new Swiper('.js-horizontal-scroll', {
    wrapperClass: 'c2-horizontal-scroll__wrapper',
    slideClass: 'c2-horizontal-scroll-item',
    navigation: {
      nextEl: '.c2-horizontal-scroll__next',
      prevEl: '.c2-horizontal-scroll__prev'
    },
    slidesPerView: 'auto',
    loop: false,
    allowTouchMove: false,
    spaceBetween: 20,
    slidesPerGroup: 6
  });
});
