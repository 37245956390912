import { createApp } from 'vue';
import { useBlockLink } from '../../composables/useBlockLink';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.js-block-link')).forEach((el) => {
    const app = createApp({
      setup() {
        return {
          ...useBlockLink()
        };
      }
    });

    app.use(bugsnagVue).mount(el);
  });
});
