import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import UserAPI from './../../api_clients/User';
import ActivityList from './../components/ActivityList';
import errorNotification from './../../utils/ErrorNotification';
import { bugsnagVue } from '../../utils/BugsnagInit';

const userAPI = new UserAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-header-activity')) {
    const jsHeaderActivity = document.getElementById('js-header-activity');
    const app = createApp({
      data() {
        return {
          show: false,
          userId: null,
          activities: [],
          nextPage: false,
          count: 0,
          startDate: null
        };
      },

      created: function () {
        eventHub.$on('more-read', this.moreRead);
      },

      mounted: function () {
        this.$nextTick(() => {
          const dataSet = this.$el.parentElement.dataset;
          if (!this.userId) {
            this.userId = dataSet.userId;
          }
          this.count = parseInt(dataSet.count, 10);
        });
      },

      beforeUnmount: function () {
        eventHub.$off('more-read', this.moreRead);
      },

      computed: {
        hasActivity: function () {
          return this.activities.length > 0;
        }
      },

      methods: {
        onClickVisible: function () {
          this.show = !this.show;
          if (this.userId && this.show && this.activities.length === 0) {
            this.count = 0;
            this.fetchActivities();
          }
        },

        onClickMoreRead: function () {
          this.fetchActivities();
        },

        fetchActivities: function () {
          const params = this.startDate ? { startDate: this.startDate } : null;

          userAPI
            .fetchActivities(this.userId, params)
            .then((response) => {
              this.setActivitiesData(response.data);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        setActivitiesData: function (data) {
          this.count = 0;
          this.activities = this.activities.concat(data.activities);
          this.nextPage = data.nextPage;
          if (data.activities.length > 0) {
            this.startDate = data.activities[data.activities.length - 1].createdAt;
          }
        },

        onClickClose: function () {
          this.show = false;
        },

        moreRead: function () {
          this.onClickMoreRead();
        }
      },

      components: {
        ActivityList
      }
    });

    app.use(bugsnagVue).mount(jsHeaderActivity);
  }
});
