import { createApp } from 'vue';
import HeaderModalMenuBase from './../components/HeaderModalMenuBase';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-header')) {
    const app = createApp({
      data: function () {
        return {
          isShowMenuMovie: false,
          isShowMenuDrama: false,
          isShowMenuAnime: false
        };
      },

      computed: {
        MenuItemMovieClass: function () {
          return {
            'is-active': this.isShowMenuMovie
          };
        },

        MenuItemDramaClass: function () {
          return {
            'is-active': this.isShowMenuDrama
          };
        },

        MenuItemAnimeClass: function () {
          return {
            'is-active': this.isShowMenuAnime
          };
        }
      },

      methods: {
        showMenuMovie: function () {
          this.isShowMenuMovie = true;
          this.isShowMenuDrama = false;
          this.isShowMenuAnime = false;
        },

        hideMenuMovie: function () {
          this.isShowMenuMovie = false;
        },

        onMouseoutMenuMovie: function (event) {
          const relatedTargetEl = event.relatedTarget;

          if (relatedTargetEl === null) {
            this.hideMenuMovie();
            return;
          }

          if (
            /p-header-modal-menu/.test(relatedTargetEl.className) === true ||
            /js-header-nav-movie/.test(relatedTargetEl.className) === true
          ) {
            return;
          }

          this.hideMenuMovie();
        },

        showMenuDrama: function () {
          this.isShowMenuDrama = true;
          this.isShowMenuMovie = false;
          this.isShowMenuAnime = false;
        },

        hideMenuDrama: function () {
          this.isShowMenuDrama = false;
        },

        onMouseoutMenuDrama: function (event) {
          const relatedTargetEl = event.relatedTarget;

          if (relatedTargetEl === null) {
            this.hideMenuDrama();
            return;
          }

          if (
            /p-header-modal-menu/.test(relatedTargetEl.className) === true ||
            /js-header-nav-drama/.test(relatedTargetEl.className) === true
          ) {
            return;
          }

          this.hideMenuDrama();
        },

        showMenuAnime: function () {
          this.isShowMenuAnime = true;
          this.isShowMenuMovie = false;
          this.isShowMenuDrama = false;
        },

        hideMenuAnime: function () {
          this.isShowMenuAnime = false;
        },

        onMouseoutMenuAnime: function (event) {
          const relatedTargetEl = event.relatedTarget;

          if (relatedTargetEl === null) {
            this.hideMenuAnime();
            return;
          }

          if (
            /p-header-modal-menu/.test(relatedTargetEl.className) === true ||
            /js-header-nav-anime/.test(relatedTargetEl.className) === true
          ) {
            return;
          }

          this.hideMenuAnime();
        }
      },

      components: {
        HeaderModalMenuBase
      }
    });

    app.use(bugsnagVue).mount('.js-header');
  }
});
