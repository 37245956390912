import { ref } from 'vue';
import PeopleLabel from '../common/components/PeopleLabel';
import UserAPI from '../api_clients/User';
import errorNotification from '../utils/ErrorNotification';

const userAPI = new UserAPI();

export const useUserFanPeople = () => {
  const filmarksId = ref('');
  const people = ref([]);
  const nextPage = ref(true);
  const page = ref(2);

  const fetchFanPeople = () => {
    const params = {
      page: page.value
    };

    userAPI
      .fetchFanPeople(filmarksId.value, params)
      .then((response) => {
        setFanPeopleData(response.data);
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  const setFanPeopleData = (data) => {
    people.value = people.value.concat(data.people);
    nextPage.value = data.nextPage;
    page.value += 1;
  };

  const onClickMoreRead = () => {
    fetchFanPeople();
  };

  const onHookMounted = () => {
    filmarksId.value = document.querySelector('#js-users-fans-people').dataset.filmarksId;
  };

  return {
    filmarksId,
    people,
    nextPage,
    page,
    fetchFanPeople,
    setFanPeopleData,
    onClickMoreRead,
    onHookMounted,
    PeopleLabel
  };
};
