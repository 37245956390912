import { createApp } from 'vue';
import isLocalStorageSupported from './../../utils/IsLocalStorageSupported';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('js-login') || document.getElementById('js-register');
  if (el) {
    const app = createApp({
      mounted: function () {
        if (isLocalStorageSupported()) {
          localStorage.removeItem('is_twitter_share');
        }
      }
    });

    app.use(bugsnagVue).mount(el);
  }
});
