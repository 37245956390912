import UserAgent from '../../utils/UserAgent';

export default {
  data: function () {
    return {
      isAllowedEnv: false,
      isAllowedBrowser: false,
      isAllowedDevice: false,
      allowedBrowser: ['Chrome', 'Firefox'],
      allowedDevice: ['PC']
    };
  },

  created: function () {
    this.checkAllowedEnv();
  },

  methods: {
    checkAllowedBrowser: function () {
      const ua = new UserAgent();
      const browserName = ua.getBrowserName();
      if (this.allowedBrowser.indexOf(browserName) !== -1) {
        this.isAllowedBrowser = true;
      }
    },

    checkAllowedDevice: function () {
      const ua = new UserAgent();
      const deviceName = ua.getDevice();
      if (this.allowedDevice.indexOf(deviceName) !== -1) {
        this.isAllowedDevice = true;
      }
    },

    checkAllowedEnv: function () {
      this.checkAllowedBrowser();
      this.checkAllowedDevice();

      if (this.isAllowedBrowser && this.isAllowedDevice) {
        this.isAllowedEnv = true;
      }
    }
  }
};
