export const CATEGORY_ID_REGIST = 1;
export const CATEGORY_ID_FOLLOW = 2;
export const CATEGORY_ID_RELEASED_THEATER = 3;
export const CATEGORY_ID_RELEASED_RENTAL = 4;
export const CATEGORY_ID_REGIST_FILM = 5;
export const CATEGORY_ID_MARK = 11;
export const CATEGORY_ID_CLIP = 21;
export const CATEGORY_ID_COMMENT = 31;
export const CATEGORY_ID_COMMENT_REPLY = 32;
export const CATEGORY_ID_FAN_COMMENT = 33;
export const CATEGORY_ID_FAN_COMMENT_REPLY = 34;
export const CATEGORY_ID_LIKE = 41;
export const CATEGORY_ID_FAN_LIKE = 42;
export const CATEGORY_ID_MARK_COMMENT_LIKE = 43;
export const CATEGORY_ID_MOVIE_REVIEW_CAMPAIGN = 91;
