import { createApp } from 'vue';
import OnlinePreviewAllowedEnv from './../modules/OnlinePreviewAllowedEnv';
import OnlinePreviewPlayer from '../components/OnlinePreviewPlayer';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-online-preview-sample')) {
    const app = createApp({
      mixins: [OnlinePreviewAllowedEnv],

      data() {
        return {
          autoplay: false,
          isSample: true
        };
      },

      components: {
        OnlinePreviewPlayer
      }
    });

    app.use(bugsnagVue).mount('#js-online-preview-sample');
  }
});
