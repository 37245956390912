import { createApp } from 'vue';
import getCurrentPosition from '../../common/modules/GetCurrentPosition';
import PiaTheaterAPI from '../../api_clients/PiaTheater';
import errorNotification from '../../utils/ErrorNotification';
import TheatersHere from '../components/TheatersHere';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.js-theaters-here')).forEach((el) => {
    const app = createApp({
      el,
      data() {
        return {
          theaters: [],
          latitude: null,
          longitude: null,
          isLoading: true,
          isReadMore: false,
          isLocationError: false
        };
      },
      methods: {
        coords: function () {
          return {
            latitude: this.latitude,
            longitude: this.longitude
          };
        },
        fetchTheaters: function (coords, offset) {
          const piaTheaterAPI = new PiaTheaterAPI();
          const params = { ...coords, ...{ offset: offset } };

          return piaTheaterAPI.fetchNearbyTheaters(params);
        },
        readMore: function () {
          const coords = this.coords();
          const offset = this.theaters.length;

          this.isLoading = true;
          this.isReadMore = false;

          this.fetchTheaters(coords, offset)
            .then((response) => {
              if (response.request.status !== 200) {
                throw new Error(response);
              }

              this.theaters = [...this.theaters, ...response.data.piaTheaters];
              this.isLoading = false;
              this.isReadMore = true;
            })
            .catch((error) => {
              errorNotification(error);
            });
        }
      },
      mounted: function () {
        getCurrentPosition()
          .then((position) => {
            const { latitude, longitude } = position.coords;

            this.latitude = latitude;
            this.longitude = longitude;

            return this.coords();
          })
          .then((coords) => this.fetchTheaters(coords))
          .then((response) => {
            if (response.request.status !== 200) {
              throw new Error(response);
            }

            this.theaters = response.data.piaTheaters;
            this.isLoading = false;
            this.isReadMore = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.isLocationError = true;
          });
      },
      components: {
        TheatersHere
      }
    });

    app.use(bugsnagVue).mount(el);
  });
});
