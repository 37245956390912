import { createApp } from 'vue';
import { useAccordionToggle } from '../../composables/useAccordionToggle';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-accordion-toggle'), (AccordionToggleEl) => {
    const app = createApp({
      setup() {
        return {
          ...useAccordionToggle()
        };
      }
    });

    app.use(bugsnagVue).mount(AccordionToggleEl);
  });
});
