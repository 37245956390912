import Base from './Base';

export default class PiaTheater extends Base {
  constructor() {
    super();
  }

  fetchNearbyTheaters(params) {
    return this.client.get('/pia_theaters', { params: params });
  }

  fetchMovies(theaterId, params) {
    return this.client.get(`/pia_theaters/${theaterId}/movies`, { params: params });
  }

  fetchMovie(theaterId, movieId, params) {
    return this.client.get(`/pia_theaters/${theaterId}/movies/${movieId}`, { params: params });
  }

  fetchRecentMovies(theaterId, params) {
    return this.client.get(`/pia_theaters/${theaterId}/recent/movies`, { params: params });
  }
}
