import { createApp } from 'vue';
import { useAnchorLink } from '../../composables/useAnchorLink';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-anchor-link')) {
    const jsAnchorLink = document.getElementById('js-anchor-link');
    const app = createApp({
      setup() {
        return {
          ...useAnchorLink()
        };
      }
    });

    app.use(bugsnagVue).mount(jsAnchorLink);
  }
});
