import { createApp, onBeforeUnmount, onMounted } from 'vue';
import { useTermAccept } from '../../composables/useTermAccept';
import AcceptModal from './../components/AcceptModal';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-term-accept')) {
    const app = createApp({
      setup() {
        const {
          isAccepted,
          updateAcceptsPath,
          isApiProcessing,
          modalType,
          onHookMounted,
          onHookBeforeUnmount,
          toBoolean,
          updateTermAccept
        } = useTermAccept();

        onMounted(() => {
          onHookMounted();
        });

        onBeforeUnmount(() => {
          onHookBeforeUnmount();
        });

        return {
          isAccepted,
          updateAcceptsPath,
          isApiProcessing,
          modalType,
          toBoolean,
          updateTermAccept
        };
      },
      components: {
        AcceptModal
      }
    });

    app.use(bugsnagVue).mount('#js-term-accept');
  }
});
