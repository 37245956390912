import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

document.addEventListener('DOMContentLoaded', () => {
  new Swiper('.js-trailer-collection-horizontal-scroll', {
    wrapperClass: 'c2-trailer-collection-horizontal-scroll__wrapper',
    slideClass: 'c2-trailer-card',
    navigation: {
      nextEl: '.c2-trailer-collection-horizontal-scroll__next',
      prevEl: '.c2-trailer-collection-horizontal-scroll__prev'
    },
    slidesPerView: 'auto',
    loop: false,
    allowTouchMove: false,
    spaceBetween: 20,
    slidesPerGroup: 4
  });
});
