import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import eventHub from '../common/modules/EventHub';

export const acceptModalProps = {
  updateAcceptsPath: {
    type: String
  },
  isAccepted: {
    type: Boolean
  },
  isApiProcessing: {
    type: Boolean
  },
  modalType: {
    type: String
  }
};

export const useAcceptModal = (props) => {
  const html = ref(null);
  const body = ref(null);
  const modalOpenedClassName = ref('is-modal-open');

  const show = computed(() => {
    return props.isAccepted === false;
  });

  const addModalStyle = () => {
    html.value.classList.add(modalOpenedClassName.value);
    body.value.classList.add(modalOpenedClassName.value);
  };

  const removeModalStyle = () => {
    html.value.classList.remove(modalOpenedClassName.value);
    body.value.classList.remove(modalOpenedClassName.value);
  };

  const onClickAccept = () => {
    eventHub.$emit(`update-${props.modalType}-accept`);
    removeModalStyle();
  };

  onMounted(() => {
    html.value = document.querySelector('html');
    body.value = document.querySelector('body');
    addModalStyle();
  });

  onBeforeUnmount(() => {
    removeModalStyle();
  });

  return {
    html,
    body,
    show,
    onClickAccept
  };
};
