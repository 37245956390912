import MyAPI from '../api_clients/My';
import errorNotification from '../utils/ErrorNotification';
import { ref } from 'vue';

const myAPI = new MyAPI();

export const useMyMailAccepted = () => {
  const isMailAccepted = ref(false);

  const onClickMailAccepted = () => {
    updateMailAccepted();
  };

  const updateMailAccepted = () => {
    myAPI
      .updateMailAccepted({ isMailAccepted: !isMailAccepted.value })
      .then((response) => {
        setUserMailAcceptedData(response.data);
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  const setUserMailAcceptedData = (data) => {
    isMailAccepted.value = data.mailAccepts.isMailAccepted;
  };

  const onHookMounted = () => {
    const element = document.getElementById('js-my-mail-accepted');
    isMailAccepted.value = element.dataset.isMailAccepted === 'true';
  };

  return {
    isMailAccepted,
    onClickMailAccepted,
    onHookMounted
  };
};
