import { createApp } from 'vue';
import { useUserFanPeople } from './../../composables/useUserFanPeople';
import { bugsnagVue } from '../../utils/BugsnagInit';
import PeopleLabel from '../../common/components/PeopleLabel';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-users-fans-people')) {
    const app = createApp({
      components: {
        PeopleLabel
      },
      setup() {
        const {
          filmarksId,
          people,
          nextPage,
          page,
          fetchFanPeople,
          setFanPeopleData,
          onClickMoreRead,
          onHookMounted,
          PeopleLabel
        } = useUserFanPeople();

        onHookMounted();

        return {
          filmarksId,
          people,
          nextPage,
          page,
          fetchFanPeople,
          setFanPeopleData,
          onClickMoreRead,
          PeopleLabel
        };
      }
    });

    app.use(bugsnagVue).mount('#js-users-fans-people');
  }
});
