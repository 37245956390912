import { createApp, onMounted } from 'vue';
import { useMyMailAccepted } from '../../composables/useMyMailAccepted';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-my-mail-accepted')) {
    const app = createApp({
      setup() {
        const { isMailAccepted, onClickMailAccepted, onHookMounted } = useMyMailAccepted();

        onMounted(() => {
          onHookMounted();
        });

        return {
          isMailAccepted,
          onClickMailAccepted
        };
      }
    });

    app.use(bugsnagVue).mount('#js-my-mail-accepted');
  }
});
