import eventHub from '../common/modules/EventHub';
import { ref } from 'vue';

export const useMyProfileImage = () => {
  const defaultUserImagePath = ref('');
  const isSetProfileImage = ref('false');
  const show = ref(false);
  const showDeleteBtn = ref(false);
  const showModal = ref(false);
  const previewProfileImage = ref(null);
  const removeProfileImage = ref(null);
  const profileImage = ref(null);

  const openModal = () => {
    showModal.value = true;
  };

  const closeModal = () => {
    showModal.value = false;
  };

  const onClickSelectFiles = () => {
    closeModal();
  };

  const onChangeProfileImage = (e) => {
    if (e) {
      e.preventDefault();
    }
    closeModal();

    const files = e.target.files;
    const url_object = window.URL || window.webkitURL;
    let blob_url = null;

    if (files[0]) {
      blob_url = url_object.createObjectURL(files[0]);
    }
    if (blob_url && previewProfileImage.value) {
      previewProfileImage.value.src = blob_url;
      // reset delete flag
      if (removeProfileImage.value && removeProfileImage.value.previousElementSibling) {
        removeProfileImage.value.checked = false;
        removeProfileImage.value.previousElementSibling.value = '0';
      }
      show.value = true;
      showDeleteBtn.value = true;
    }
  };

  const onDeleteProfileImage = () => {
    // clear input data
    if (profileImage.value) {
      profileImage.value.value = '';
    }

    // set delete flag
    if (removeProfileImage.value && removeProfileImage.value.previousElementSibling) {
      removeProfileImage.value.checked = true;
      removeProfileImage.value.previousElementSibling.value = '1';
    }

    // set default image
    if (previewProfileImage.value) {
      previewProfileImage.value.src = defaultUserImagePath.value;
    }
    closeModal();
    show.value = true;
    showDeleteBtn.value = false;
  };

  const onHookMounted = (dataset) => {
    defaultUserImagePath.value = dataset.defaultUserImagePath;
    isSetProfileImage.value = dataset.isSetProfileImage;

    if (isSetProfileImage.value === 'true') {
      showDeleteBtn.value = true;
    }
  };

  const onHookBeforeUnmount = () => {
    eventHub.$off('close-modal', closeModal);
  };

  return {
    defaultUserImagePath,
    isSetProfileImage,
    show,
    showDeleteBtn,
    showModal,
    previewProfileImage,
    removeProfileImage,
    profileImage,
    openModal,
    closeModal,
    onClickSelectFiles,
    onChangeProfileImage,
    onDeleteProfileImage,
    onHookMounted,
    onHookBeforeUnmount
  };
};
