import { createApp } from 'vue';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useFacebookRelation } from '../../composables/useFacebookRelation';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-facebook-relation')) {
    const app = createApp({
      setup() {
        const { isRelated, onClickRelation, onHookMounted } = useFacebookRelation();
        onHookMounted();

        return {
          isRelated,
          onClickRelation
        };
      }
    });

    app.use(bugsnagVue).mount('#js-facebook-relation');
  }
});
