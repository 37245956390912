import { ref } from 'vue';
import SnsAPI from '../api_clients/SNS';
import errorNotification from '../utils/ErrorNotification';
import * as message from '../constants/Message';
import * as url from '../constants/URL';

const snsAPI = new SnsAPI();

export const useAppleRelation = () => {
  const isRelated = ref(false);

  const onAppleRelationHookMounted = (dataset) => {
    isRelated.value = dataset.apple_related === 'true';
  };

  const onClickRelation = () => {
    if (!isRelated.value) {
      createToken();
    } else {
      if (window.confirm(message.DELETE_APPLE_RELATION_CONFIRM)) {
        deleteToken();
      }
    }
  };

  const createToken = () => {
    snsAPI.createAppleToken();
  };

  const deleteToken = () => {
    snsAPI
      .deleteAppleToken()
      .then((response) => {
        if (response.data && response.data.user.login_prohibited_by_password == true) {
          if (window.confirm(message.SETTING_PASSWORD_CONFIRM)) {
            window.location.href = url.UPDATE_PASSWORD;
          }
        } else {
          isRelated.value = false;
        }
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  return {
    isRelated,
    onAppleRelationHookMounted,
    createToken,
    deleteToken,
    onClickRelation
  };
};
