import { ref } from 'vue';

export const useLocationSelectModal = () => {
  const isShowAreaModal = ref(false);
  const isShowPrefModal = ref(false);

  const showSelectPrefModal = () => {
    isShowPrefModal.value = true;
  };

  const hideSelectPrefModal = () => {
    isShowPrefModal.value = false;
  };

  const showSelectAreaModal = () => {
    isShowAreaModal.value = true;
  };

  const hideSelectAreaModal = () => {
    isShowAreaModal.value = false;
  };

  return {
    onClickSelectPrefecture: showSelectPrefModal,
    onClickSelectArea: showSelectAreaModal,
    onClickPrefModalOverlay: hideSelectPrefModal,
    onClickAreaModalOverlay: hideSelectAreaModal,
    onClickPrefClose: hideSelectPrefModal,
    onClickAreaClose: hideSelectAreaModal,
    isShowAreaModal,
    isShowPrefModal,
    showSelectPrefModal,
    hideSelectPrefModal,
    showSelectAreaModal,
    hideSelectAreaModal
  };
};
