import { ref } from 'vue';

export const useReadMore = () => {
  const isShowAll = ref(false);

  const showAll = () => {
    isShowAll.value = true;
  };
  const hideLatter = () => {
    isShowAll.value = false;
  };

  return {
    isShowAll,
    showAll,
    hideLatter
  };
};
