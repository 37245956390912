import eventHub from '../common/modules/EventHub';
import TermAcceptAPI from '../api_clients/TermAccept';
import errorNotification from '../utils/ErrorNotification';
import { ref } from 'vue';

const termAcceptAPI = new TermAcceptAPI();

export const useTermAccept = () => {
  const isAccepted = ref(false);
  const updateAcceptsPath = ref('');
  const isApiProcessing = ref(false);
  const modalType = ref('term');

  const onHookMounted = () => {
    eventHub.$on('update-term-accept', updateTermAccept);
    updateAcceptsPath.value = document.querySelector('#js-term-accept').dataset.updateAcceptsPath;
  };

  const onHookBeforeUnmount = () => {
    eventHub.$off('update-term-accept', updateTermAccept);
  };

  const toBoolean = (val) => {
    return val === 'true';
  };

  const updateTermAccept = () => {
    isApiProcessing.value = true;
    termAcceptAPI
      .updateTermAccept()
      .then((response) => {
        if (response.data.user.isAgreement === true) {
          isAccepted.value = true;
        }
        isApiProcessing.value = false;
      })
      .catch((response) => {
        errorNotification(response);
        isApiProcessing.value = false;
      });
  };

  return {
    isAccepted,
    updateAcceptsPath,
    isApiProcessing,
    modalType,
    onHookMounted,
    onHookBeforeUnmount,
    toBoolean,
    updateTermAccept
  };
};
