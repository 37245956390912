import { createApp } from 'vue';
import ContentDetailSynopsis from './../components/ContentDetailSynopsis';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-content-detail-synopsis')) {
    const app = createApp({
      components: {
        ContentDetailSynopsis
      }
    });

    app.use(bugsnagVue).mount('#js-content-detail-synopsis');
  }
});
