import Base from './Base';

export default class People extends Base {
  constructor() {
    super();
  }

  // ファン 対象の人物のFanになる
  savePeopleFan(personId, params) {
    return this.client.post(`/people/${personId}/fans`, params);
  }

  // ファン 対象の人物のFanをやめる
  deletePeopleFan(personId, fanId, params) {
    return this.client.delete(`/people/${personId}/fans/${fanId}`, params);
  }

  // ユーザ Fanにいいね！したユーザー一覧
  fetchFanLikeUsers(personId, fanId, params) {
    return this.client.get(`/people/${personId}/fans/${fanId}/likes/users`, { params: params });
  }

  // FanLike Fankにいいね！ 登録
  saveFanLike(personId, fanId, params) {
    return this.client.post(`/people/${personId}/fans/${fanId}/likes`, params);
  }

  // FanLike Fanにつけたいいね！ 解除
  deleteFanLike(personId, fanId, fanLikeId, params) {
    return this.client.delete(`/people/${personId}/fans/${fanId}/likes/${fanLikeId}`, params);
  }

  // FanComment コメントを取得
  fetchFanComment(personId, fanId, params) {
    return this.client.get(`/people/${personId}/fans/${fanId}/comments`, { params: params });
  }

  // FanComment コメントを投稿
  saveFanComment(personId, fanId, params) {
    return this.client.post(`/people/${personId}/fans/${fanId}/comments`, params);
  }

  // FanComment コメントを削除
  deleteFanComment(personId, fanId, fanCommentId, params) {
    return this.client.delete(`/people/${personId}/fans/${fanId}/comments/${fanCommentId}`, params);
  }
}
