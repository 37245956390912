import { createApp, onBeforeUnmount, onMounted } from 'vue';
import { usePrivacyPolicy } from '../../composables/usePrivacyPolicy';
import AcceptModal from './../components/AcceptModal';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-privacy-policy-accept')) {
    const app = createApp({
      setup() {
        const {
          isAccepted,
          updateAcceptsPath,
          isApiProcessing,
          modalType,
          toBoolean,
          updatePrivacyPolicyAccept,
          onHookMounted,
          onHookBeforeUnmount
        } = usePrivacyPolicy();

        onMounted(() => {
          onHookMounted();
        });

        onBeforeUnmount(() => {
          onHookBeforeUnmount();
        });

        return {
          isAccepted,
          updateAcceptsPath,
          isApiProcessing,
          modalType,
          toBoolean,
          updatePrivacyPolicyAccept
        };
      },
      components: {
        AcceptModal
      }
    });

    app.use(bugsnagVue).mount('#js-privacy-policy-accept');
  }
});
