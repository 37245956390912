import axios from 'axios';

const host = window.location.host;
const protocol = window.location.protocol;

let apiBase = '/api';
if (/^https/.test(protocol) === true) {
  if (/-web\./.test(host) === true) {
    apiBase = 'https://' + host.replace('-web.', '-api.');
  } else {
    apiBase = 'https://api.' + host;
  }
}

export default class ApiBase {
  constructor() {
    this.client = this._createClient(apiBase);
  }

  _createClient(apiBase) {
    const token = document.getElementsByName('csrf-token')[0].content;

    if (token) {
      return axios.create({
        baseURL: apiBase,
        timeout: 6000,
        headers: { 'X-CSRF-Token': token }
      });
    } else {
      return axios.create({
        baseURL: apiBase,
        timeout: 6000
      });
    }
  }
}
