import { createApp } from 'vue';
import { useGetStartDate } from '../../composables/useGetStartDate.js';
import PiaTheaterAPI from './../../api_clients/PiaTheater';
import errorNotification from './../../utils/ErrorNotification';
import TheaterMovies from './../components/TheaterMovies';
import { bugsnagVue } from '../../utils/BugsnagInit';

const piaTheaterAPI = new PiaTheaterAPI();

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-theater-schedule'), (theaterSchedule) => {
    const app = createApp({
      setup() {
        return {
          ...useGetStartDate()
        };
      },
      data() {
        return {
          theaterId: '',
          scheduleDate: '',
          movieId: '',
          movies: [],
          hasMovies: false,
          hasLink: false,
          isMovieRetrieving: false,
          selectedDateId: 0,
          fetchMoviesTimer: null,
          isShowLoading: true,
          prefectureId: ''
        };
      },

      mounted: function () {
        const { dataset } = this.$el.parentElement;

        this.theaterId = dataset.theaterId;
        this.movieId = dataset.movieId;
        this.prefectureId = dataset.prefectureId;
        this.setInitialScheduleDate();
        this.fetchMovies();
      },

      computed: {
        classDateItem: function () {
          return {
            disabled: this.isMovieRetrieving
          };
        }
      },

      methods: {
        clearAll: function () {
          this.movies = [];
        },

        setInitialScheduleDate: function () {
          this.scheduleDate = this.getStartDate();
        },

        fetchMovies: async function () {
          this.updateShowLoading(true);

          const params = {
            scheduleDate: this.scheduleDate,
            movieId: this.movieId
          };

          await piaTheaterAPI
            .fetchMovies(this.theaterId, params)
            .then((response) => {
              this.updateShowLoading(false);
              this.appendMovies(response.data.movies);
              this.updateHasMovies();
            })

            .catch((response) => {
              this.updateShowLoading(false);
              errorNotification(response);
            });
        },

        appendMovies: function (movies) {
          movies.forEach((movie) => {
            this.movies.push(movie);
          });
        },

        updateShowLoading: function (bool) {
          this.isShowLoading = bool;
        },

        updateHasMovies: function () {
          this.hasMovies = this.movies.length > 0;
        },

        onClickScheduleDate: function (id, date) {
          this.selectedDateId = id;
          this.scheduleDate = date;
          this.clearAll();
          this.fetchMovies();
        }
      },

      components: {
        TheaterMovies
      }
    });

    app.use(bugsnagVue).mount(theaterSchedule);
  });
});
