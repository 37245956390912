import Base from './Base';

export default class TermAccept extends Base {
  constructor() {
    super();
  }

  updateTermAccept(params) {
    return this.client.patch('/agreement');
  }
}
