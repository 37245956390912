import { createApp } from 'vue';
import RawContentDetailSynopsis from './../components/RawContentDetailSynopsis';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-raw-content-detail-synopsis')) {
    const app = createApp({
      components: {
        RawContentDetailSynopsis
      }
    });

    app.use(bugsnagVue).mount('#js-raw-content-detail-synopsis');
  }
});
