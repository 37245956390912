import { createApp, onBeforeUnmount, onMounted } from 'vue';
import listModal from './../components/ListModal';
import { useMyProfileImage } from '../../composables/useMyProfileImage';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  const myProfileImageId = document.getElementById('js-my-profile-image');

  if (myProfileImageId) {
    const app = createApp({
      setup() {
        const {
          defaultUserImagePath,
          isSetProfileImage,
          show,
          showDeleteBtn,
          showModal,
          previewProfileImage,
          removeProfileImage,
          profileImage,
          openModal,
          closeModal,
          onClickSelectFiles,
          onChangeProfileImage,
          onDeleteProfileImage,
          onHookMounted,
          onHookBeforeUnmount
        } = useMyProfileImage();

        onMounted(() => {
          onHookMounted(myProfileImageId.dataset);
        });

        onBeforeUnmount(() => {
          onHookBeforeUnmount();
        });

        return {
          defaultUserImagePath,
          isSetProfileImage,
          show,
          showDeleteBtn,
          showModal,
          previewProfileImage,
          removeProfileImage,
          profileImage,
          openModal,
          closeModal,
          onClickSelectFiles,
          onChangeProfileImage,
          onDeleteProfileImage
        };
      },
      components: {
        listModal
      }
    });

    app.use(bugsnagVue).mount('#js-my-profile-image');
  }
});
