import Qs from 'qs';

const rails4EmptyArrayReplacer = function (prefix, value) {
  if (value == null || typeof value == 'undefined') {
    value = '';
  }
  if (Array.isArray(value) && value.length === 0) {
    return [''];
  }
  return value;
};

export const transformRequest = function (data) {
  return Qs.stringify(data, { arrayFormat: 'brackets', filter: rails4EmptyArrayReplacer });
};

export const timeFormat = (input, sepType = '') => {
  // input = '2017-06-08T14:24:37+09:00'
  const YYYY = input.slice(0, 4);
  const MM = input.slice(5, 7);
  const DD = input.slice(8, 10);
  const HH = input.slice(11, 13);
  const mm = input.slice(14, 16);
  const sep = sepType === 'hyphen' ? '-' : '/';

  return `${YYYY}${sep}${MM}${sep}${DD} ${HH}:${mm}`;
};
