import { createApp } from 'vue';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-header-my-menu')) {
    const app = createApp({
      data() {
        return {
          show: false
        };
      },

      methods: {
        onClickVisible: function () {
          this.show = !this.show;
        },
        onClickClose: function () {
          this.show = false;
        }
      }
    });

    app.use(bugsnagVue).mount('#js-header-my-menu');
  }
});
