import { createApp } from 'vue';
import FollowBlock from '../components/FollowBlock';
import Block from '../components/Block';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-user-card')) {
    const app = createApp({
      components: {
        FollowBlock,
        Block
      }
    });

    app.use(bugsnagVue).mount('.js-user-card');
  }
});
