import Base from './Base';

export default class Area extends Base {
  constructor() {
    super();
  }

  fetchNearestAreas(params) {
    return this.client.get(`/nearest/pia_areas`, { params: params });
  }
}
