import Swiper, { Navigation } from 'swiper';
import { ref, computed } from 'vue';

Swiper.use([Navigation]);

export const useMovieScheduleSwiper = () => {
  const hideSwiper = ref(false);

  const hideSwiperClass = computed(() => {
    return {
      'hide-swiper': hideSwiper.value
    };
  });

  const generateSwiper = () => {
    new Swiper('.c-content-schedule__show-time-carousel.is-active', {
      wrapperClass: 'c-content-schedule__show-time-carousel__wrapper',
      slideClass: 'c-content-schedule__show-time-carousel-item',
      navigation: {
        nextEl: '.c-content-schedule__show-time-carousel__next',
        prevEl: '.c-content-schedule__show-time-carousel__prev'
      },
      slidesPerView: 'auto',
      loop: false,
      spaceBetween: 20,
      slidesOffsetAfter: 40,
      on: {
        init: function () {
          this.params.slidesPerGroup = Math.floor(this.width / 105);
          this.update(true);
        }
      }
    });
  };

  return {
    hideSwiper,
    hideSwiperClass,
    generateSwiper
  };
};
