import SnsAPI from '../api_clients/SNS';
import errorNotification from '../utils/ErrorNotification';
import * as message from '../constants/Message';
import isLocalStorageSupported from '../utils/IsLocalStorageSupported';
import { ref } from 'vue';

const snsAPI = new SnsAPI();

export const useTwitterRelation = () => {
  const isRelated = ref(false);
  const isRelatedClip = ref(false);

  const onHookMounted = (data) => {
    isRelated.value = data?.twitter_related === 'true';
    isRelatedClip.value = data?.twitter_related_clip === 'true';
  };

  const onClickRelation = () => {
    if (!isRelated.value) {
      createToken();
    } else {
      if (window.confirm(message.DELETE_TWITTER_RELATION_CONFIRM)) {
        deleteToken();
      }
    }
  };

  const onClickClip = () => {
    if (!isRelatedClip.value) {
      updateRelationClip(true);
    } else {
      updateRelationClip(false);
    }
  };

  const createToken = () => {
    snsAPI.createTwitterToken();
  };

  const deleteToken = () => {
    snsAPI
      .deleteTwitterToken()
      .then((response) => {
        isRelated.value = false;
        if (isLocalStorageSupported()) {
          localStorage.removeItem('is_twitter_share');
        }
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  const updateRelationClip = (clip) => {
    const param = {
      isClipShare: clip
    };

    snsAPI
      .updateTwitterClipShare(param)
      .then((response) => {
        isRelatedClip.value = clip;
      })
      .catch((response) => {
        errorNotification(response);
      });
  };

  return {
    isRelated,
    isRelatedClip,
    onHookMounted,
    onClickRelation,
    onClickClip,
    createToken,
    deleteToken,
    updateRelationClip
  };
};
