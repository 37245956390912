import PeopleAPI from './../../api_clients/People';
import errorNotification from './../../utils/ErrorNotification';

const peopleAPI = new PeopleAPI();

export default {
  data: function () {
    return {
      fan: {
        id: null,
        userId: null,
        personId: null,
        count: null
      },
      isActive: false
    };
  },

  mounted: function () {
    this.$nextTick(() => {
      const { dataset } = this.$el.parentElement;

      this.fan.id = dataset.id;
      this.fan.personId = dataset.person_id;
      this.fan.count = parseInt(dataset.count, 10);
      this.isActive = dataset.active === 'true';
    });
  },

  methods: {
    onClickButton: function () {
      if (this.isActive) {
        this.deletePeopleFan();
      } else {
        this.savePeopleFan();
      }
    },

    savePeopleFan: function () {
      peopleAPI
        .savePeopleFan(this.fan.personId)
        .then((response) => {
          this.setPeopleFanData(response.data);
          this.isActive = true;
        })
        .catch((response) => {
          errorNotification(response);
        });
    },

    deletePeopleFan: function () {
      peopleAPI
        .deletePeopleFan(this.fan.personId, this.fan.id)
        .then((response) => {
          this.setPeopleFanData(response.data);
          this.isActive = false;
        })
        .catch((response) => {
          errorNotification(response);
        });
    },

    setPeopleFanData: function (data) {
      this.fan = data.fan;
    }
  }
};
