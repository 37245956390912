import { ref } from 'vue';

export const useAccordionRadio = () => {
  const opened_id = ref(null);

  const isAccordionOpen = (id) => {
    return opened_id.value === id;
  };

  const clickItem = (id) => {
    if (isAccordionOpen(id)) {
      opened_id.value = null;
    } else {
      opened_id.value = id;
    }
  };

  return {
    opened_id,
    isAccordionOpen,
    clickItem
  };
};
