import _findIndex from 'lodash/findIndex';
import { createApp } from 'vue';
import errorNotification from './../../utils/ErrorNotification';
import MarkCommentItem from './../components/MarkCommentItem';
import MarkPostComment from './../components/MarkPostComment';
import MovieAPI from './../../api_clients/Movie';
import { bugsnagVue } from '../../utils/BugsnagInit';

const movieAPI = new MovieAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-review-detail-comment')) {
    const app = createApp({
      data() {
        return {
          movieId: null,
          markId: null,
          markComments: [],
          nextPage: false,
          page: 1,
          count: 0
        };
      },

      mounted: function () {
        const { dataset } = this.$el.parentElement;

        this.movieId = dataset.movieId;
        this.markId = dataset.markId;
        this.count = dataset.commentCount;

        this.fetchComment();
      },

      methods: {
        onClickMoreRead: function () {
          this.fetchComment();
        },

        fetchComment: function () {
          const params = {
            page: this.page
          };

          movieAPI
            .fetchMarkComment(this.movieId, this.markId, params)
            .then((response) => {
              this.setMarkCommentsData(response.data);
              this.page = this.page + 1;
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        setMarkCommentsData: function (data) {
          this.nextPage = data.nextPage;
          this.markComments = this.markComments.concat(data.markComments).sort(function (a, b) {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          });
        },

        setCount: function (data) {
          this.count = data.markComment.count;
        },

        setMarkCommentData: function (data) {
          data.markComment.myComment = true;
          data.markComment.deletable = true;
          this.markComments.push(data.markComment);
        },

        removeMarkCommentData: function (markCommentId) {
          const deleteIndex = _findIndex(this.markComments, { id: markCommentId });

          if (deleteIndex >= 0) {
            this.markComments.splice(deleteIndex, 1);
          }
        },

        openSpoiler: function (markCommentId) {
          const spoilerIndex = _findIndex(this.markComments, { id: markCommentId });

          if (spoilerIndex >= 0) {
            this.markComments[spoilerIndex].isSpoiler = false;
          }
        }
      },

      components: {
        MarkCommentItem,
        MarkPostComment
      }
    });

    app.use(bugsnagVue).mount('#js-review-detail-comment');
  }
});
