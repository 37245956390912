import { createApp, onMounted } from 'vue';
import { useClip } from '../../composables/useClip';
import { usePcCassette } from '../../composables/usePcCassette';
import MarkEditor from './../components/MarkEditor';
import ModalOverlay from './../components/ModalOverlay';
import { bugsnagVue } from '../../utils/BugsnagInit';
import { useMark } from '../../composables/useMark';
import { useCategory } from '../../composables/useCategory';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-cassette'), (cassette) => {
    const app = createApp({
      setup() {
        const category = useCategory();
        const { clip, onHookMounted, viewingClipCount, onClickClipButton } = useClip(category);
        const {
          mark,
          onMarkHookMounted,
          viewingMarkCount,
          changeSpoiler,
          changeShareTwitter,
          closeEditor,
          editorSaveMark,
          editorDeleteMark,
          onClickMarkButton,
          removeMarkData
        } = useMark(category);

        onMounted(() => {
          onHookMounted(cassette.dataset.clip);
          onMarkHookMounted(cassette.dataset);
        });

        return {
          clip,
          viewingClipCount,
          onClickClipButton,
          mark,
          viewingMarkCount,
          changeSpoiler,
          changeShareTwitter,
          closeEditor,
          editorSaveMark,
          editorDeleteMark,
          onClickMarkButton,
          removeMarkData,
          ...usePcCassette()
        };
      },
      components: {
        ModalOverlay,
        MarkEditor
      }
    });

    app.use(bugsnagVue).mount(cassette);
  });
});
