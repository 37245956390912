export const useFollow = () => {
  const userRelationship = {
    id: null,
    userId: null,
    targetUserId: null,
    state: null,
    count: null
  };

  const getUserRelationship = (data) => {
    const { id, target_user_id, state } = data;

    return {
      id,
      state,
      targetUserId: target_user_id
    };
  };

  return {
    userRelationship,
    getUserRelationship
  };
};
