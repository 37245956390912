import { createApp } from 'vue';
import _throttle from 'lodash/throttle';
import JackDfpAdItem from './../components/JackDfpAdItem';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-jack_dfp_ad')) {
    const app = createApp({
      data: function () {
        return {
          targetElement: null,
          isIntersecting: false,
          defaultMargin: 20
        };
      },

      mounted: function () {
        this.initChecker();
        this.onScroll();
      },

      beforeUnmount: function () {
        this.offScroll();
      },

      methods: {
        initChecker: function () {
          this.targetElement = this.$el.parentElement.parentElement; // 親要素(l-main)

          const adHeight = this.$el.parentElement.childNodes[0].clientHeight + this.defaultMargin * 2; // DFP広告の高さ + 上下マージン
          const targetHEight = this.targetElement.clientHeight; // l-mainの高さ
          if (adHeight > targetHEight) {
            this.targetElement.style.minHeight = `${adHeight}px`;
          }

          this.checker();
        },

        checker: _throttle(function () {
          const targetElOffsetTop = this.targetElement.offsetTop + this.defaultMargin;
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

          if (targetElOffsetTop <= scrollTop) {
            this.isIntersecting = true;
          } else {
            this.isIntersecting = false;
          }
        }, 50),

        onScroll: function () {
          window.addEventListener('scroll', this.checker);
        },

        offScroll: function () {
          window.removeEventListener('scroll', this.checker);
        }
      },

      components: {
        JackDfpAdItem
      }
    });

    app.use(bugsnagVue).mount('#js-jack_dfp_ad');
  }
});
