import { createApp } from 'vue';
import { useFeed } from '../../composables/useFeed';
import FeedList from './../components/FeedList';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-feed')) {
    const app = createApp({
      setup() {
        return {
          ...useFeed()
        };
      },
      components: {
        FeedList
      }
    });

    app.use(bugsnagVue).mount('#js-feed');
  }
});
