import Base from './Base';

export default class My extends Base {
  constructor() {
    super();
  }

  // メルマガON / OFF
  updateMailAccepted(params) {
    return this.client.patch(`/my/mail_accept`, params);
  }
}
