import { createApp } from 'vue';
import TheaterCard from './../components/TheaterCard';
import { useTheaterCard } from './../../composables/useTheaterCard';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.js-theater-card')).forEach((el) => {
    const theaterId = el.dataset.theaterId;

    const app = createApp({
      setup() {
        const { movies, totalMovie, isShow } = useTheaterCard(theaterId);
        return { movies, totalMovie, isShow };
      },
      components: {
        TheaterCard
      }
    });

    app.use(bugsnagVue).mount(el);
  });
});
