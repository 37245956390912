import { createApp, onMounted } from 'vue';
import { useAppleRelation } from '../../composables/useAppleRelation';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  const appleRelationEl = document.getElementById('js-apple-relation');
  if (appleRelationEl) {
    const app = createApp({
      setup() {
        const { isRelated, onAppleRelationHookMounted, createToken, deleteToken, onClickRelation } = useAppleRelation();

        onMounted(() => {
          onAppleRelationHookMounted(appleRelationEl.dataset);
        });

        return {
          isRelated,
          onAppleRelationHookMounted,
          createToken,
          deleteToken,
          onClickRelation
        };
      }
    });

    app.use(bugsnagVue).mount('#js-apple-relation');
  }
});
