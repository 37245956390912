import axios from 'axios';

export default {
  data: function () {
    return {
      client: null,
      baseURL: '',
      timeout: 10000,
      csrfToken: ''
    };
  },

  methods: {
    createClient: function (requestMethod) {
      if (requestMethod !== 'get') {
        this.csrfToken = document.getElementsByName('csrf-token')[0].content;
      }

      if (this.csrfToken) {
        this.client = axios.create({
          baseURL: this.baseURL,
          timeout: this.timeout,
          headers: {
            'X-CSRF-Token': this.csrfToken
          }
        });
      } else {
        this.client = axios.create({
          baseURL: this.baseURL,
          timeout: this.timeout
        });
      }
    },

    deleteClient: function () {
      this.client = null;
    }
  }
};
