import { createApp } from 'vue';
import eventHub from './../../common/modules/EventHub';
import FanLikeUserList from './../components/FanLikeUserList';
import ErrorModal from './../components/ErrorModal';
import errorNotification from './../../utils/ErrorNotification';
import PeopleAPI from './../../api_clients/People';
import { bugsnagVue } from '../../utils/BugsnagInit';

const peopleAPI = new PeopleAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-fan-like-user')) {
    const app = createApp({
      data() {
        return {
          personId: '',
          fanId: '',
          fanLikeId: '',
          fanLikeCount: 0,
          isUserSignedIn: 'false',
          isFaned: 'false',
          users: [],
          nextPage: 1,
          isApiProcessing: false
        };
      },

      created: function () {
        eventHub.$on('fetch-fan-like-users', this.fetchFanLikeUsers);
        eventHub.$on('save-fan-like', this.saveFanLike);
        eventHub.$on('delete-fan-like', this.deleteFanLike);
      },

      mounted: function () {
        this.$nextTick(() => {
          const { dataset } = this.$el.parentElement;

          this.personId = dataset.personId;
          this.fanId = dataset.fanId;
          this.fanLikeId = dataset.fanLikeId;
          this.fanLikeCount = dataset.fanLikeCount;
          this.isUserSignedIn = dataset.isUserSignedIn;
          this.isFaned = dataset.isFaned;

          this.fetchFanLikeUsers();
        });
      },

      beforeUnmount: function () {
        eventHub.$off('fetch-fan-like-users', this.fetchFanLikeUsers);
        eventHub.$off('save-fan-like', this.saveFanLike);
        eventHub.$off('delete-fan-like', this.deleteFanLike);
      },

      methods: {
        fetchFanLikeUsers: function () {
          const params = {
            page: this.nextPage
          };

          peopleAPI
            .fetchFanLikeUsers(this.personId, this.fanId, params)
            .then((response) => {
              this.setFanLikeUsersData(response.data);
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        saveFanLike: function () {
          this.isApiProcessing = true;
          peopleAPI
            .saveFanLike(this.personId, this.fanId)
            .then((response) => {
              this.setFanLikeData(response.data);
              this.addMarkLikeUserData(response.data.fanLike);
              this.isFaned = true;
              this.isApiProcessing = false;
            })
            .catch((response) => {
              this.isApiProcessing = false;
              this.$refs.errorModal.showIfDetail(response);
            });
        },

        deleteFanLike: function () {
          this.isApiProcessing = true;
          peopleAPI
            .deleteFanLike(this.personId, this.fanId, this.fanLikeId)
            .then((response) => {
              this.setFanLikeData(response.data);
              this.removeMarkLikeUserData(response.data.fanLike);
              this.isFaned = false;
              this.isApiProcessing = false;
            })
            .catch((response) => {
              this.isApiProcessing = false;
              errorNotification(response);
            });
        },

        setFanLikeData: function (data) {
          this.fanLikeId = data.fanLike.id;
          this.fanLikeCount = data.fanLike.count;
        },

        setFanLikeUsersData: function (data) {
          this.users = this.users.concat(data.users);
          this.nextPage = data.nextPage;
        },

        addMarkLikeUserData: function (data) {
          this.users.unshift(data.user);
        },

        removeMarkLikeUserData: function (data) {
          this.users.some((user, i) => {
            if (user.id === data.user.id) this.users.splice(i, 1);
          });
        }
      },

      components: {
        FanLikeUserList,
        ErrorModal
      }
    });

    app.use(bugsnagVue).mount('#js-fan-like-user');
  }
});
