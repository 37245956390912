import { onMounted, onBeforeUnmount, ref } from 'vue';
import eventHub from '../common/modules/EventHub';
import FeedAPI from '../api_clients/Feed';
import errorNotification from '../utils/ErrorNotification';

const feedAPI = new FeedAPI();

export const useFeed = () => {
  const userId = ref(null);
  const feeds = ref([]);
  const nextPage = ref(false);
  const startDate = ref(null);
  const fetched = ref(false);
  const isApiProcessing = ref(false);

  function onClickMoreRead() {
    isApiProcessing.value = true;
    fetchFeeds();
  }

  function fetchFeeds() {
    const params = startDate.value ? { startDate: startDate.value } : null;

    feedAPI
      .fetchFeeds(params)
      .then((response) => {
        setFeedsData(response.data);
        isApiProcessing.value = false;
      })
      .catch((response) => {
        errorNotification(response);
        isApiProcessing.value = false;
      });
  }

  function setFeedsData(data) {
    feeds.value = feeds.value.concat(data.feeds);
    nextPage.value = data.nextPage;
    startDate.value = data.feeds.length > 0 ? data.feeds[data.feeds.length - 1].createdAt : null;
    fetched.value = true;
  }

  onMounted(() => {
    eventHub.$on('on-click-more-read', onClickMoreRead);
    fetchFeeds();
  });

  onBeforeUnmount(() => {
    eventHub.$off('on-click-more-read', onClickMoreRead);
  });

  return {
    userId,
    feeds,
    nextPage,
    startDate,
    fetched,
    isApiProcessing,
    onClickMoreRead
  };
};
