export default function isLocalStorageSupported() {
  let isSupported = false;

  try {
    if (window.localStorage) {
      const test = '__localstorage-test__';

      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);

      isSupported = true;
    }
  } catch (e) {
    if (e.name === 'SecurityError') {
      // eslint-disable-next-line no-console
      console.error('Local storage is not available due to security problem');
    }
  }

  return isSupported;
}
