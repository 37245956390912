import { ref } from 'vue';

export const useEpisodeReadMore = () => {
  const isClick = ref(false);
  const threshold = ref(10);

  const readMore = (el) => {
    isClick.value = true;
  };

  const isReadMore = (count) => {
    return isClick.value || count < threshold.value;
  };

  const isReadMoreButton = () => {
    return !isClick.value;
  };

  const onEpisodeReadMoreMounted = (thresholdValue) => {
    threshold.value = thresholdValue;
  };

  return {
    isClick,
    readMore,
    isReadMore,
    isReadMoreButton,
    onEpisodeReadMoreMounted
  };
};
