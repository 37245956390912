export const DEFAULT_SELECT_AREA_ID = 9999;
export const DEFAULT_SELECT_AREA_LABEL = '指定なし';
export const DEFAULT_SP_SELECT_AREA_LABEL = 'エリア指定なし';
export const OTHER_AREA_CATEGORY_LABEL = 'その他';
export const TOGGLE_MODE_LOCATION = 'location';
export const TOGGLE_MODE_PREFECTURE = 'prefecture';
export const OFFSET_ADDITION = 5;
export const LIMIT_NUMBER_WITHOUT_PAGINATING = 1000;
export const NOT_SUPPORT_GEO_LOCATION_ERROR_MESSAGE = 'ご使用中の環境では現在地情報の取得ができません。';
export const ERROR_GEO_LOCATION_SUPPORT_PAGE_URL = 'https://filmarks.zendesk.com/hc/ja/articles/360015536213';
