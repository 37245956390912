import _findIndex from 'lodash/findIndex';
import { createApp } from 'vue';
import PeopleAPI from './../../api_clients/People';
import errorNotification from './../../utils/ErrorNotification';
import FanCommentItem from './../components/FanCommentItem';
import FanPostComment from './../components/FanPostComment';
import * as message from './../../constants/Message';
import { bugsnagVue } from '../../utils/BugsnagInit';

const peopleAPI = new PeopleAPI();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-fan-detail-comment')) {
    const app = createApp({
      data: function () {
        return {
          personId: null,
          fanId: null,
          fanComments: [],
          nextPage: true,
          page: 1,
          count: 0
        };
      },

      mounted: function () {
        this.$nextTick(() => {
          const { dataset } = this.$el.parentElement;

          this.personId = dataset.personId;
          this.fanId = dataset.fanId;
          this.count = dataset.count;

          this.fetchComment();
        });
      },

      methods: {
        onClickMoreRead: function (e) {
          if (e) {
            e.preventDefault();
          }
          this.fetchComment();
        },

        onClickDelete: function (fanCommentId, e) {
          if (e) {
            e.preventDefault();
          }
          if (window.confirm(message.DELETE_COMMENT_CONFIRM)) {
            this.deleteComment(parseInt(fanCommentId, 10), e);
          }
        },

        fetchComment: function () {
          const params = {
            page: this.page
          };

          peopleAPI
            .fetchFanComment(this.personId, this.fanId, params)
            .then((response) => {
              this.setFanCommentsData(response.data);
              this.page = this.page + 1;
            })
            .catch((response) => {
              errorNotification(response);
            });
        },

        setCount: function (data) {
          this.count = data.fanComment.count;
        },

        setFanCommentData: function (data) {
          this.fanComments.push(data.fanComment);
        },

        setFanCommentsData: function (data) {
          this.nextPage = data.nextPage;
          this.fanComments = this.fanComments.concat(data.fanComments).sort(function (a, b) {
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          });
        },

        removeFanCommentData: function (fanCommentId) {
          const deleteIndex = _findIndex(this.fanComments, { id: fanCommentId });

          if (deleteIndex >= 0) {
            this.fanComments.splice(deleteIndex, 1);
          }
        },

        toBoolean: function (val) {
          return val === 'true';
        }
      },

      components: {
        FanCommentItem,
        FanPostComment
      }
    });

    app.use(bugsnagVue).mount('#js-fan-detail-comment');
  }
});
