import Base from './Base';

export default class User extends Base {
  constructor() {
    super();
  }

  // Activity List
  fetchActivities(userId, params) {
    return this.client.get(`/users/${userId}/activities`, { params: params });
  }

  // 人物 ユーザー別
  fetchFanPeople(userId, params) {
    return this.client.get(`/users/${userId}/fans.json`, { params: params });
  }

  // UserRelationship 対象の人物をフォローする
  saveUserRelationship(userId, params) {
    return this.client.post(`/users/${userId}/followings`, params);
  }

  // UserRelationship 対象の人物のフォローを解除する
  deleteUserRelationship(userId, relationId, params) {
    return this.client.delete(`/users/${userId}/followings/${relationId}`, params);
  }

  // UserRelationship 対象の人物をブロックする
  saveUserBlockRelationship(userId, params) {
    return this.client.post(`/users/${userId}/blocks`, params);
  }

  // UserRelationship 対象の人物をブロックを解除する
  deleteUserBlockRelationship(userId, relationId, params) {
    return this.client.delete(`/users/${userId}/blocks/${relationId}`, params);
  }
}
