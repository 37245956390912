import { createApp } from 'vue';
import Fan from '../modules/Fan';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-btn-fan'), (btn) => {
    const app = createApp({
      mixins: [Fan]
    });

    app.use(bugsnagVue).mount(btn);
  });
});
