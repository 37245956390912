export const createGoogleMapUrl = (theaterName) => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(theaterName)}`;
};

export const createTheaterDistance = (distance) => {
  const _distance = parseInt(distance, 10);

  if (isNaN(_distance)) {
    return '';
  }

  if (_distance < 100) {
    return `(${_distance}m)`;
  } else {
    const formattedKmDistance = (Math.round((_distance / 1000) * 10) / 10).toFixed(1);
    return `(${formattedKmDistance}km)`;
  }
};
