import { createApp } from 'vue';
import { useGetStartDate } from '../../composables/useGetStartDate.js';
import PiaTheaterAPI from './../../api_clients/PiaTheater';
import errorNotification from './../../utils/ErrorNotification';
import TheaterMovies from './../components/TheaterMovies';
import { bugsnagVue } from '../../utils/BugsnagInit';

const piaTheaterAPI = new PiaTheaterAPI();

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-movie-theater-schedule'), (movieTheaterSchedule) => {
    const app = createApp({
      setup() {
        return {
          ...useGetStartDate()
        };
      },
      data() {
        return {
          theaterId: '',
          scheduleDate: '',
          movieId: '',
          movies: [],
          hasMovies: false,
          hasLink: false,
          isMovieRetrieving: false,
          selectedDateId: 0,
          fetchMoviesTimer: null,
          isShowLoading: true
        };
      },

      mounted: function () {
        this.theaterId = this.$el.parentElement.dataset.theaterId;
        this.movieId = this.$el.parentElement.dataset.movieId;
        this.setInitialScheduleDate();
        this.fetchMovie();
      },

      computed: {
        classDateItem: function () {
          return {
            disabled: this.isMovieRetrieving
          };
        }
      },

      methods: {
        clearAll: function () {
          this.hasMovies = false;
          this.movies = [];
        },

        setInitialScheduleDate: function () {
          this.scheduleDate = this.getStartDate();
        },

        fetchMovie: async function () {
          this.updateShowLoading(true);

          const params = {
            scheduleDate: this.scheduleDate
          };

          piaTheaterAPI
            .fetchMovie(this.theaterId, this.movieId, params)
            .then((response) => {
              this.updateShowLoading(false);
              if (response.request.status === 200) {
                this.appendMovie(response.data.movie);
                this.updateHasMovies();
              } else if (response.request.status !== 204) {
                return Promise.reject(response);
              }
            })
            .catch((response) => {
              this.updateShowLoading(false);
              errorNotification(response);
            });
        },

        appendMovie: function (movie) {
          this.movies.push(movie);
        },

        updateShowLoading: function (bool) {
          this.isShowLoading = bool;
        },

        updateHasMovies: function () {
          this.hasMovies = this.movies.length > 0;
        },

        onClickScheduleDate: function (id, date) {
          this.selectedDateId = id;
          this.scheduleDate = date;
          this.clearAll();
          this.fetchMovie();
        }
      },

      components: {
        TheaterMovies
      }
    });

    app.use(bugsnagVue).mount(movieTheaterSchedule);
  });
});
