import { createApp } from 'vue';
import Block from '../components/Block';
import FollowBlock from '../components/FollowBlock';
import { bugsnagVue } from '../../utils/BugsnagInit';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('js-profile')) {
    const app = createApp({
      components: {
        Block,
        FollowBlock
      }
    });

    app.use(bugsnagVue).mount('#js-profile');
  }
});
