class EventBusEvent extends Event {
  constructor({ type, data }) {
    super(type);
    this.data = data;
  }
}

class EventBus extends EventTarget {
  static _instance;

  static getInstance() {
    if (!this._instance) this._instance = new EventBus();
    return this._instance;
  }

  $emit(type, ...args) {
    const argsAsHash = args.reduce(function (acc, current) {
      acc = { ...acc, ...current };
      return acc;
    }, {});
    this.dispatchEvent(new EventBusEvent({ type, data: argsAsHash }));
  }

  $on(type, callback) {
    this.addEventListener(type, callback);
  }
  $off(type, callback) {
    this.removeEventListener(type, callback);
  }
}

export default EventBus.getInstance();
