import Base from './Base';

export default class Feed extends Base {
  constructor() {
    super();
  }

  // Feed
  fetchFeeds(params) {
    return this.client.get('/feeds', { params: params });
  }
}
